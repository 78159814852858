import { stringify } from "@amcharts/amcharts4/.internal/core/utils/Utils";
import { Brand } from "../../lib/brands";
import panasonicLogo from '../../layout/logos/PanasonicBusinessLogo.svg';
import peakTechLogo from '../../layout/logos/peakTechLogo-light-bg.png';
import ComputacenterLogo from '../../layout/logos/ComputacenterLogo.svg';
import elemezLogo from '../../layout/imgs/elemezLogo.png';
import { fontWeight, FontWeights } from "./globalStyle";

type Themes = { [brand in Brand]: Theme };

export enum ButtonColours {
  blue = 'blue',
  green = 'green',
  red = 'red',
  grey = 'grey'
}

const buttonColours = [
  ButtonColours.blue,
  ButtonColours.green,
  ButtonColours.red,
  ButtonColours.grey
] as const;
export type ButtonColour = typeof buttonColours[number];

export interface ButtonColourProps {
  dark: string,
  light: string,
  borderColour?: string,
  textColour?: string
}

export enum FontSizes {
  eightPixels = 'eightPixels',
  tenPixels = 'tenPixels',
  twelvePixels = 'twelvePixels',
  thirteenPixels = 'thirteenPixels',
  fourteenPixels = 'fourteenPixels',
  fifteenPixels = 'fifteenPixels',
  sixteenPixels = 'sixteenPixels',
  eighteenPixels = 'eighteenPixels',
  twentyPixels = 'twentyPixels'
}

const fontSizes = [
  FontSizes.eightPixels,
  FontSizes.tenPixels,
  FontSizes.twelvePixels,
  FontSizes.thirteenPixels,
  FontSizes.fourteenPixels,
  FontSizes.fifteenPixels,
  FontSizes.sixteenPixels,
  FontSizes.eighteenPixels,
  FontSizes.twentyPixels
] as const;
export type FontSize = typeof fontSizes[number];

export enum SectionBorderColours {
  light = 'light',
  dark = 'dark'
}
const sectionBorderColours = [
  SectionBorderColours.light,
  SectionBorderColours.dark
] as const;
export type SectionBorderColour = typeof sectionBorderColours[number];

export interface Theme {
  name: Brand,
  buttons: {
    [ButtonColours.blue]: ButtonColourProps,
    [ButtonColours.green]: ButtonColourProps,
    [ButtonColours.red]: ButtonColourProps,
    [ButtonColours.grey]: ButtonColourProps,
    white: string
  },
  links: {
    color: string
  },
  navigationBar: {
    tabs: {
      background: {
        default: string,
        hover: string,
        active: string
      },
      borderBottom: {
        active: string,
        hover: string
      },
      color: {
        default: string,
        active: string,
        hover: string
      }
    }
  },
  font: {
    sizes: {
      [FontSizes.eightPixels]: string,
      [FontSizes.tenPixels]: string,
      [FontSizes.twelvePixels]: string,
      [FontSizes.thirteenPixels]: string,
      [FontSizes.fourteenPixels]: string,
      [FontSizes.fifteenPixels]: string,
      [FontSizes.sixteenPixels]: string,
      [FontSizes.eighteenPixels]: string,
      [FontSizes.twentyPixels]: string
    },
    weights: {
      [FontWeights.normal]: string,
      [FontWeights.semiBold]: string,
      [FontWeights.bold]: string
    }
  },
  sectionBorders: {
    colours: {
      [SectionBorderColours.dark]: string,
      [SectionBorderColours.light]: string
    }
  },
  headers: {
    background: string,
    contrastBackground: string,
    dropdown: {
      default: string,
      hover: string
    },
    text: string,
    highlight: string,
    logo: string
  }
}

export const main: Theme = {
  name: 'elemez',
  buttons: {
    blue: {
      light: '#0080a9',
      dark: '#006790'
    },
    green: {
      light: '#32a478',
      dark: '#198b5f',
    },
    red: {
      light: '#c03b3b',
      dark: '#a72222'
    },
    grey: {
      light: '#f3f7fb',
      dark: '#d7e2eb',
      borderColour: '#c2d1e0',
      textColour: '#3a5769'
    },
    white: '#fff'
  },
  links: {
    color: '#07c'
  },
  navigationBar: {
    tabs: {
      background: {
        default: '#315d87',
        hover: '#18446e',
        active: '#18446e'
      },
      borderBottom: {
        active: '#2e99ed',
        hover: '#315d87'
      },
      color: {
        default: '#fff',
        active: '#fff',
        hover: '#fff'
      }
    },
  },
  font: { /* based on base font size of 16px */
    sizes: {
      eightPixels: '0.5rem',
      tenPixels: '0.625rem',
      twelvePixels: '0.75rem',
      thirteenPixels: '0.813rem',
      fourteenPixels: '0.875rem',
      fifteenPixels: '0.938rem',
      sixteenPixels: '1rem',
      eighteenPixels: '1.125rem',
      twentyPixels: '1.25rem'
    },
    weights: {
      normal: fontWeight.normal,
      semiBold: fontWeight.semiBold,
      bold: fontWeight.bold
    }
  },
  sectionBorders: {
    colours: {
      light: '#edf1f3',
      dark: '#c2d1e0'
    },
  },
  headers: {
    background: '#4a76a0',
    contrastBackground: '#3e6387',
    dropdown: {
      default: '#3e6387',
      hover: '#5880a7'
    },
    text: '#fff',
    highlight: '#5880a7',
    logo: elemezLogo
  }
};

export const panasonic: Theme = {
  ...main,
  name: 'panasonic',
  navigationBar: {
    tabs: {
      background: {
        default: '#fff',
        hover: '#edf1f5',
        active: '#fff'
      },
      borderBottom: {
        active: '#0072af',
        hover: '#0072af'
      },
      color: {
        default: '#80b9d7',
        hover: '#0072af',
        active: '#0072af'
      }
    },
  },
  headers: {
    background: '#fff',
    contrastBackground: '#F1F1F1',
    dropdown: {
      default: '#fff',
      hover: '#F1F1F1'
    },
    text: '#333',
    highlight: '#F1F1F1',
    logo: panasonicLogo
  }
};

export const peaktech: Theme = {
  ...main,
  name: 'peaktech',
  navigationBar: {
    tabs: {
      background: {
        default: '#fff',
        hover: '#edf1f5',
        active: '#fff'
      },
      borderBottom: {
        active: '#87D444',
        hover: '#87D444'
      },
      color: {
        default: '#80b9d7',
        hover: '#0072af',
        active: '#0072af'
      }
    },
  },
  headers: {
    background: '#fff',
    contrastBackground: '#F1F1F1',
    dropdown: {
      default: '#fff',
      hover: '#F1F1F1'
    },
    text: '#333',
    highlight: '#F1F1F1',
    logo: peakTechLogo
  }
};

export const computacenter: Theme = {
  ...main,
  name: 'computacenter',
  navigationBar: {
    tabs: {
      background: {
        default: '#fff',
        hover: '#fff',
        active: '#fff'
      },
      borderBottom: {
        active: '#469BD6',
        hover: '#469BD6'
      },
      color: {
        default: '#469BD6',
        hover: '#28377A',
        active: '#28377A'
      }
    },
  },
  headers: {
    background: '#fff',
    contrastBackground: '#F1F1F1',
    dropdown: {
      default: '#fff',
      hover: '#F1F1F1'
    },
    text: '#28377A',
    highlight: '#F1F1F1',
    logo: ComputacenterLogo
  }
};

export const themes: Themes = {
  elemez: main,
  panasonic,
  peaktech,
  computacenter
};
