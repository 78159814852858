import * as React from "react";

import { useTranslation } from "@lib/useTypedTranslation";

import styled from "styled-components";

import { useIsExternalUser } from "./externalUser";
import { SignOut } from "./signOut";

import { productNames } from '../../containers/app/productsWrapper';
import { Theme } from "../../containers/app/themes";

import { getEnabledSettings } from "../../containers/settings/enabledSettings";

import { useAgreementsContext } from "../../context/agreements";
import { useBrandContext } from "../../context/brand";
import { useCurrentUserContext } from "../../context/currentUser";
import { useFeatureTogglesContext } from "../../context/featureToggles";
import { useProductsContext } from "../../context/products";

import { useWorldRoutes } from "../../routes/parts/allWorldRoutes";
import { DropdownItem, DropdownMenu } from "../controls/dropdownMenu";
import { AboutPopup } from "../../containers/about/about";

const StyledUserIcon = styled.i`
  ${({ theme }: { theme: Theme }) => `background: ${theme.headers.contrastBackground}`};
  margin-right: 5px;
  line-height: 28px;
  min-width: 28px;
  border-radius: 50%;
`;

StyledUserIcon.displayName = 'UserIcon';

const StyledUserDropdownText = styled.span`
  background: inherit;

  max-width: 348px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media (max-width: 1800px) {
    max-width: 298px;
  }
  @media (max-width: 1650px) {
    max-width: 168px;
  }
  @media (max-width: 1300px) {
    max-width: 103px;
  }
  @media (max-width: 1000px) {
    max-width: 75px;
  }
  @media (max-width: 850px) {
    display: none;
  }
`;

StyledUserDropdownText.displayName = 'UserDropdownText';

const StyledLinkIcon = styled.i`
  background: transparent;
  margin-right: .5rem;
  width: 1.5rem;
  text-align: center;
`;

StyledLinkIcon.displayName = 'LinkIcon';

export interface IUserDropdownProps {
  worldAware: boolean
}

const WorldAwareMenu: React.FC = () => {
  const { t } = useTranslation('translation');

  const { agreementsToAccept } = useAgreementsContext();
  const featureToggles = useFeatureTogglesContext();
  const routes = useWorldRoutes();
  const currentUser = useCurrentUserContext();
  const productsData = useProductsContext();
  const { isExternalUser } = useIsExternalUser(currentUser.email);

  return <>
    {(agreementsToAccept.length === 0 || !isExternalUser) && <>
      {productsData[productNames.coreV2].enabled && <>
        {(currentUser.isAdministrator || currentUser.isManager || currentUser.isEditor) && !currentUser.homeLocationId && <>
          <DropdownItem
            key="bulkActions"
            href={`#` + routes.bulkActions}
            id="bulkActionsDropdownBtn"
          >
            <StyledLinkIcon className="fas fa-boxes" />
            {t('BULK_ACTIONS', { ns: 'bulkActions' })}
          </DropdownItem>
        </>}
      </>}
      {(currentUser.isAdministrator || currentUser.isManager) && <>
        {!featureToggles.hideEnrollmentPage && <DropdownItem
          key="enrollment"
          href={`#` + routes.enrollment}
          id="enrollmentDropdownBtn"
        >
          <StyledLinkIcon className="fas fa-plus-square faicon" />
          {t('ENROLLMENT')}
        </DropdownItem>}
        <DropdownItem
          key="homeLocations"
          href={`#` + routes.homeLocationManagement}
          id="homeLocationsDropdownBtn"
        >
          <StyledLinkIcon className="fas fa-map-marked-alt" />
          {t('HOME_LOCATION_other')}
        </DropdownItem>
        <DropdownItem
          key="zones"
          href={`#` + routes.zones.list}
          id="zonesDropdownBtn"
        >
          <StyledLinkIcon className="fas fa-record-vinyl" />
          {t('MANAGE_ZONES', { ns: 'zones' })}
        </DropdownItem>
        {!!getEnabledSettings(productsData).length && <DropdownItem
          key="settings"
          id="settingsDropdownBtn"
          href={`#` + routes.settings.basePath}
        >
          <StyledLinkIcon className="fa fa-cog faicon" />
          {t('SETTINGS')}
        </DropdownItem>}
      </>}
      {currentUser.isAdministrator && !featureToggles.hideUserManagement && <DropdownItem
        id="userManagementDropdownBtn"
        key="userManagement"
        href={`#` + routes.userManagement}
      >
        <StyledLinkIcon className="fas fa-users-cog" />
        {t('USER_MANAGEMENT', { ns: 'userManagement' })}
      </DropdownItem>}
    </>}
  </>;
};

export const UserDropdown: React.FC<IUserDropdownProps> = ({ worldAware }) => {
  const { t } = useTranslation('translation');

  const { isPanasonic } = useBrandContext();
  const currentUser = useCurrentUserContext();
  const [showAboutPopup, setShowAboutPopup] = React.useState(false);

  const usernameText = `${currentUser.email}${currentUser.isAdministrator ? ` (${t('ADMIN')})` : ''}`;

  return <><DropdownMenu
    data-id="user-dropdown"
    buttonContent={<>
      <StyledUserIcon className="fa fa-user faicon" />
      <StyledUserDropdownText>{usernameText}</StyledUserDropdownText>
    </>}
    right
  >
    {worldAware && <WorldAwareMenu />}
    <DropdownItem
      id="helpDropdownBtn"
      key="help-center"
      href={isPanasonic ? "https://smartessentialshelpcentre.b2msolutions.com" : "https://elemezresourcecentre.b2msolutions.com"}
    >
      <StyledLinkIcon className="fas fa-question-circle" />
      {t('HELP_CENTER')}
    </DropdownItem>
    <DropdownItem id="aboutDropdownBtn" onClick={() => { setShowAboutPopup(true); }}>
      <StyledLinkIcon className="fas fa-info-circle" />
      {t('ABOUT')}
    </DropdownItem>
    <SignOut>
      <DropdownItem id="signOutDropdownBtn">
        <StyledLinkIcon className="fa fa-sign-out-alt faicon" />
        {t('SIGN_OUT')}
      </DropdownItem>
    </SignOut>
  </DropdownMenu>
  <AboutPopup show={showAboutPopup} handleClose={() => setShowAboutPopup(false)} />
  </>;
};
