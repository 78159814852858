import React, { useState, useRef, DetailedHTMLProps, HTMLAttributes } from 'react';
import Autosuggest, { AutosuggestPropsSingleSection, ChangeEvent, InputProps, SuggestionSelectedEventData } from 'react-autosuggest';

import './autosuggestDropdownButton.css';

export interface Props<TSuggestion = any> extends Pick<AutosuggestPropsSingleSection<TSuggestion>,
  | 'id'
  | 'suggestions'
  | 'onSuggestionsFetchRequested'
  | 'getSuggestionValue'
> {
  button: React.ReactNode,
  onSelect: (suggestion: TSuggestion) => void,
  renderSuggestion?: AutosuggestPropsSingleSection<TSuggestion>['renderSuggestion'],
  disabled?: boolean,
  setOpen?: (newState: boolean | ((prevState: boolean) => boolean)) => void,
  open: boolean,
  disableInput?: true
}

export const AutosuggestDropdownButton = <T = DetailedHTMLProps<HTMLAttributes<HTMLSpanElement>, HTMLSpanElement>['children'],>(props: Props<T>) => {
  const { button, onSelect, renderSuggestion, setOpen, open, disabled, disableInput, ...autosuggestProps } = props;
  const { onSuggestionsFetchRequested } = autosuggestProps;
  const [inputValue, setInputValue] = useState('');
  const dropDownButton = useRef(null);
  const autosuggest = useRef(null);

  const onButtonClick = () => {
    if (disabled) { return; }
    setOpen((o) => !o);
    setInputValue('');
    onSuggestionsFetchRequested({ value: '', reason: 'suggestions-revealed' });
  };

  const onInputChange = (_: React.FormEvent<any>, { newValue }: ChangeEvent): void => {
    disableInput || setInputValue(newValue);
  };

  const onSuggestionSelected = (_: React.FormEvent<any>, { suggestion }: SuggestionSelectedEventData<T>): void => {
    setOpen(false);
    onSelect(suggestion);
  };

  const defaultRenderSuggestion = (suggestion: T) => {
    return <span className="autosuggest__suggestion">{suggestion as DetailedHTMLProps<HTMLAttributes<HTMLSpanElement>, HTMLSpanElement>['children']}</span>;
  };

  const inputProps: InputProps<T> = {
    value: inputValue,
    onChange: onInputChange,
    onBlur: (_: React.FocusEvent<any>) => setOpen(false),
    autoFocus: true
  };

  const renderDisabledInput = (inputProps: InputProps<T>) => {
    // we must still render the input to preserve the interactivity of the suggestions dropdown, so we hide it if disabled
    return (
      <div className="autosuggest-dropdown-button__disabled-input-wrapper">
        <input
          // these props must be passed to the input, despite the slight type mismatch
          {...inputProps as React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>}
          tabIndex={-1}
        />
      </div>
    );
  };
  return (
    <div className={`autosuggest-dropdown-button ${props.disableInput ? 'autosuggest-dropdown-button--input-disabled' : ''}`}>
      <div
        ref={dropDownButton}
        className="autosuggest-dropdown-button__button-wrapper"
        onClick={onButtonClick}
        onMouseDown={(e) => e.preventDefault()}  // prevents input blur event firing on button click
        data-id='autosuggest-dropdown-button'
      >
        {button}
      </div>
      {open &&
      <div ref={autosuggest} className="autosuggest-dropdown-button__autosuggest">
        {props.disableInput || <i className="autosuggest-dropdown-button__input-icon fas fa-search icon" />}
        <Autosuggest data-id='dropdown-autocomplete'
          { ...autosuggestProps }
          onSuggestionSelected={onSuggestionSelected}
          renderSuggestion={renderSuggestion || defaultRenderSuggestion}
          renderInputComponent={disableInput && renderDisabledInput}
          inputProps={inputProps}
          highlightFirstSuggestion={true}
          alwaysRenderSuggestions={true}
        />
      </div>
      }
    </div>
  );
};
