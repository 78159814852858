import { Product } from "../../containers/universeAdmin/addWorld";
import { request, RequestInitWithRetry } from "../../lib/request";

export const partners = [
  'barcoding us',
  'bluestar europe',
  'panasonic us',
  'panasonic australia',
  'panasonic europe',
  'peak uk',
  'peak us'
];

export const eulas = [
  'b2m-eula-0',
  'eula-barcoding-usa-20210507',
  'eula-bluestar-europe-20200807',
  'eula-panasonic-australia-20200819',
  'eula-panasonic-europe-20200811',
  'eula-panasonic-usa-20200702'
];

export const dpas = [
  'dpa-barcoding-usa-20210507',
  'dpa-bluestar-europe-20200807',
  'dpa-panasonic-australia-20200819',
  'dpa-panasonic-europe-20200811',
  'dpa-panasonic-usa-20200702'
];

export type AddWorldData = {
  name: string,
  type: 'production' | 'trial',
  partner: typeof partners[number] | null,
  retentionPeriod: 1 | 3 | 14,
  featureToggles: string[],
  product: Product,
  requiredEULA: typeof eulas[number] | null,
  requiredDPA: typeof dpas[number] | null
};

export function addWorldAction(options: RequestInitWithRetry) {
  return async (data: AddWorldData): Promise<{ success: true }> => {
    const input = {
      path: `/api/worlds/add`,
      body: { ...data }
    };
    return request(input, options, 0);
  };
}
