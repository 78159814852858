import * as React from "react";
import { getColorFromIntensity, getDiscreteBand } from "./utils";

export const Legend: React.FC<{ discrete?: boolean }> = (props) => {
  if (props.discrete) {
    return (<ul className="discrete legend">
      {new Array(10).fill(null).map((_, index) => {
        return <li key={index}>
          <div className="background">
            <div className="color" style={{ backgroundColor: getColorFromIntensity(100 - index * 10, true) }} />
          </div>
          <span className="text">{getDiscreteBand(90 - index * 10)}</span>
        </li>;
      })}
    </ul>);
  }

  return (<div className="continuous legend">
    <div className="lines">
      <div className="gradient"></div>
      <style>
        {`.continuous.legend .gradient {
            --legend-start-color: ${getColorFromIntensity(100)};
            --legend-end-color: ${getColorFromIntensity(0)};
          }`}
      </style>
    </div>
    <div className="text">
      <span>100%</span>
      <span>50%</span>
      <span>0%</span>
    </div>
  </div>);
};
