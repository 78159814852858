import { merge, cloneDeep } from 'lodash';

import { ITranslationKeys } from './keys';
import { ukTranslations } from './english-uk';
import { DeepPartial } from '../../lib/typeUtils';

// istanbul ignore file

const britishEnglishTranslations: ITranslationKeys = cloneDeep(ukTranslations);

const usTranslationsOverrides: DeepPartial<ITranslationKeys> = {
  translation: {
    UTILISATION: 'Utilization',
    ENROLLMENT: 'Enrollment',
    ENROLLMENT_DATE: 'Enrollment Date',
    ELEMEZ_CORE_V2_SUBTITLE: 'Core - Visualize and act upon key performance metrics, events and trends across the estate.',
    PANASONIC_CORE_V2_SUBTITLE: 'Smart Service - Visualize and act upon key performance metrics, events and trends across the estate.',
  },
  enrollment: {
    SECTION_TITLE: 'Enrollment',
    SECTION_SUBTITLE: 'Enrollment',
  },
  performance: {
    DEVICE_UTILISATION_PAST_30_DAYS: 'Device Utilization over the past 30 Days',
    PERFORMANCE_CHART_TITLE_DEVICES_USAGE: `Utilization of '{{ filter }}' {{ period }}`,
    PERFORMANCE_CHART_TITLE_DEVICES_USAGE_withFilter: `Utilization of '{{ filter }}' $t(translation:DEVICE_other, lowercase) {{ period }}`
  },
  alerts: {
    NO_UTILISATION_ALERT: 'No utilization problems detected with this device'
  },
  agreements: {
    AGREEMENT_DESCRIPTION: 'By accepting this agreement you warrant that you have the authority to accept and bind your organization to its content. Clicking the Accept button is considered equivalent to your signature on this agreement.',
    AGREEMENT_DESCRIPTION_other: 'By accepting these agreements you warrant that you have the authority to accept and bind your organization to their content. Clicking the Accept button is considered equivalent to your signature on these agreements.'
  },
  settings: {
    OUT_OF_CONTACT_TITLE: 'Utilization - Out of Contact Threshold',
    USAGE_TITLE: 'Utilization - Idle Threshold',
    TAB_UTILISATION: 'Utilization',
  },
  editEvents: {
    TIME_GROUP: 'Time Synchronization',
  },
  distance: {
    METRE: 'meter',
    METRE_other: 'meters',
    KILOMETRE: 'kilometer',
    KILOMETRE_other: 'kilometers'
  }
};

export const usTranslations = merge(britishEnglishTranslations, usTranslationsOverrides);
