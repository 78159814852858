
import { isNull } from 'lodash';

import { IHomeLocationDevicesCount } from '../../services/bulkActions/homeLocationChange';
import { IHomeLocation, IZone } from '../../services/config/config';
import { TTypedTFunction } from '@lib/useTypedTranslation';

export type Option<T = any> = {
  name: string,
  value: T
};

export type Group = string | null;
export type NullZone = { id: null, name: null };
export type Zone = { id: IZone['id'], name: IZone['name'] };

export const nullGroupNameKey = 'NO_GROUP';
export const nullHomeLocationNameKey = 'NO_HOME_LOCATION';
export const nullZoneNameKey = 'SELECT_ZONE';

export const nullHomeLocation: IHomeLocation = { id: null, name: null };
export const nullZone: NullZone = { id: null, name: null };

export const defaultGetNullHomeLocationName = (t: TTypedTFunction) => t(nullHomeLocationNameKey, { ns: 'editRecord' });
export const defaultGetNullGroupName = (t: TTypedTFunction) => t(nullGroupNameKey, { ns: 'translation' });
export const defaultGetNullZoneName = (t: TTypedTFunction) => t(nullZoneNameKey, { ns: 'filters' });

export const getHomeLocationName = (location: { name: string, id?: string }, t: TTypedTFunction, getNullName: (t: TTypedTFunction) => string = defaultGetNullHomeLocationName): string => {
  return isNull(location.name) ? getNullName(t) : location.name;
};

export const getGroupName = (group: Group, t: TTypedTFunction, getNullName: (t: TTypedTFunction) => string = defaultGetNullGroupName): string => {
  return isNull(group) ? getNullName(t) : group;
};

export const getZoneName = (zone: { name: string | null, id?: string | null }, t?: TTypedTFunction, getNullName: (t: TTypedTFunction) => string = defaultGetNullZoneName): string => {
  return isNull(zone.name) ? `<${getNullName(t)}>` : zone.name;
};

export const getHomeLocationNameWithDevices = (
  location: IHomeLocationDevicesCount,
  t: TTypedTFunction,
  locale: string,
  getNullNameWithDevices: (t: TTypedTFunction) => string = defaultGetNullHomeLocationName
): string => {
  // FIXME: undefined location should not be handled here.
  // This text should be passed to OptionsPicker as selectText prop, which displays when current prop is undefined.
  if (location === undefined) {
    return t('SELECT_HOME_LOCATION', { ns: 'bulkActions' });
  }
  const name = isNull(location.homeLocation) ? getNullNameWithDevices(t) : location.homeLocation.name;
  return `${name} (${location.devicesCount.toLocaleString(locale)})`;
};

/** util to create options for zones */
export const getZoneOptionMapper = (t?: TTypedTFunction, getNullName?: (t: TTypedTFunction) => string) => {
  return (zones: (Zone)[]): Option<Zone>[] =>
    zones.map(zone => ({ name: getZoneName(zone, t, getNullName), value: { id: zone.id, name: zone.name } }));
};

/** util to create options for groups */
export const getGroupOptionMapper = (t: TTypedTFunction, getNullName?: (t: TTypedTFunction) => string) => {
  return (groups: Group[]): Option<Group>[] => groups.map(g => ({ name: getGroupName(g, t, getNullName), value: g }));
};

/** util to create options for home locations */
export const getHomeLocationOptionMapper = (t: TTypedTFunction, getNullName?: (t: TTypedTFunction) => string) => {
  return (homeLocations: IHomeLocation[]): Option<IHomeLocation>[] =>
    homeLocations.map(homeLocation => ({ name: getHomeLocationName(homeLocation, t, getNullName), value: homeLocation }));
};

/** util to create options for home locations with devices */
export const getHomeLocationWithDevicesOptionMapper = (t: TTypedTFunction, locale: string, getNullName?: (t: TTypedTFunction) => string) => {
  return (homeLocations: IHomeLocationDevicesCount[]): Option<IHomeLocationDevicesCount>[] =>
    homeLocations.map(homeLocation => ({ name: getHomeLocationNameWithDevices(homeLocation, t, locale, getNullName), value: homeLocation }));
};

/** util to get suggestion classes for groups */
export const getGroupSuggestionClasses = (suggestion: Option<Group>) => {
  if (isNull(suggestion.value)) {
    return 'autosuggest__suggestion--italic';
  }
};

/** util to get suggestion classes for zones */
export const getZoneSuggestionClasses = (suggestion: Option<Zone>) => {
  if (isNull(suggestion.value.name)) {
    return 'autosuggest__suggestion--italic';
  }
};

/** util to get suggestion classes for home locations */
export const getHomeLocationSuggestionClasses = (suggestion: Option<IHomeLocation>) => {
  if (isNull(suggestion.value.name)) {
    return 'autosuggest__suggestion--italic';
  }
};

/** util to get suggestion classes for home locations with devices */
export const getHomeLocationWithDevicesSuggestionClasses = (suggestion: Option<IHomeLocationDevicesCount>) => {
  if (isNull(suggestion.value.homeLocation)) {
    return 'autosuggest__suggestion--italic';
  }
};
