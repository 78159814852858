import { useTranslation } from "@lib/useTypedTranslation";

import { ZeroSignalMap } from "../../../components/intensityMaps/zeroSignal";
import { NoServiceMap } from "../../../components/intensityMaps/noService";
import { Loading } from "../../../components/loading/loading";
import { TimePeriodAndFilterPicker } from "../../../components/timePeriodAndFilterPicker/timePeriodAndFilterPicker";
import { ShowMapFeatureToggles, ToggleableFeatures } from "../../../context/featureToggles";
import { useFilterPicker } from "../../../components/timePeriodAndFilterPicker/useFilterPicker";
import { useTimePeriodPicker } from "../../../components/timePeriodAndFilterPicker/useTimePeriodPicker";
import React from "react";

export const PoorNoSignalTab = () => {
  const { t } = useTranslation('maps');

  const {
    filter,
    optionsLoading,
    ...filterPickerProps
  } = useFilterPicker();

  const {
    timePeriod,
    ...timePeriodPickerProps
  } = useTimePeriodPicker({ timePeriods: [30, 7, 2] });

  return <>
    <div className="core-maps_header">
      <div className="core-maps_headings">
        <h2>{t('POOR_NO_SIGNAL_HEADING')}</h2>
        <h3>{t('POOR_NO_SIGNAL_SUBHEADING')}</h3>
      </div>
      <TimePeriodAndFilterPicker
        filter={filter}
        {...filterPickerProps}
        timePeriod={timePeriod}
        {...timePeriodPickerProps}
        className='core-maps_filters'
      />
    </div>
    <Loading isLoading={optionsLoading} transparentOverlay={false}>
      <ZeroSignalMap
        groups={!filter.group ? undefined : [filter.group.value]}
        homeLocations={!filter.homeLocation ? undefined : [filter.homeLocation.value.id]}
        zoneId={filter.zone?.value.id}
        timePeriod={timePeriod.value}
      />
    </Loading>
  </>;
};

export const NoServiceTab = () => {
  const { t } = useTranslation('maps');

  const {
    filter,
    optionsLoading,
    ...filterPickerProps
  } = useFilterPicker();

  const {
    timePeriod,
    ...timePeriodPickerProps
  } = useTimePeriodPicker({ timePeriods: [30, 7, 2] });

  return <>
    <div className="core-maps_header">
      <div className="core-maps_headings">
        <h2>{t('NO_SERVICE_HEADING')}</h2>
        <h3>{t('NO_SERVICE_SUBHEADING')}</h3>
      </div>
      <TimePeriodAndFilterPicker
        filter={filter}
        {...filterPickerProps}
        timePeriod={timePeriod}
        {...timePeriodPickerProps}
        className='core-maps_filters'
      />
    </div>
    <Loading isLoading={optionsLoading} transparentOverlay={false}>
      <NoServiceMap
        groups={!filter.group ? undefined : [filter.group.value]}
        homeLocations={!filter.homeLocation ? undefined : [filter.homeLocation.value.id]}
        zoneId={filter.zone?.value.id}
        timePeriod={timePeriod.value}
      />
    </Loading>
  </>;
};

export interface ITabMap {
  type: ShowMapFeatureToggles,
  tab: React.FC,
  title: string
}

export const tabsMap = [
  { type: ToggleableFeatures.showMapPoorNoSignal, title: 'POOR_NO_SIGNAL_TAB' as const, tab: PoorNoSignalTab },
  { type: ToggleableFeatures.showMapNoService, title: 'NO_SERVICE_TAB' as const, tab: NoServiceTab }
] as const;
