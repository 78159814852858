import React, { useMemo } from 'react';
import { useTranslation } from '@lib/useTypedTranslation';
import { TTypedTFunction } from '@lib/useTypedTranslation';
import styled from 'styled-components';
import { isNull } from 'lodash';

import { ModalPopup } from '../../../components/controls/modalPopup';
import { formatTitle } from '../../../components/data-table/dataFormatters';
import { IUserData } from '../../../services/users/users';
import { RoleSelection } from './roleSelection';
import { RoleTitleTooltip } from './roleTitleTooltip';
import { Option, getHomeLocationOptionMapper, getHomeLocationSuggestionClasses, nullHomeLocation, Group, getGroupOptionMapper, getZoneOptionMapper, getZoneSuggestionClasses, getGroupSuggestionClasses, nullZone, Zone } from '../../../components/controls/optionPickerLib';
import { OptionPicker } from '../../../components/controls/optionPicker';
import { IHomeLocation } from '../../../services/config/config';
import { InfoPopover } from '../../../components/tooltip/infoPopover';
import { FormControls } from '../../../components/forms/formControls';
import { UserRole } from '../../../components/authentication/userAuth';
import { Loading } from '../../../components/loading/loading';
import { RequiredIcon } from '../../../components/icons/requiredIcon';
import { CheckBoxSelection } from '../../../components/forms/checkBoxSelection';
import { World } from '../../../services/worlds/worlds';
import { CustomViewType, useCustomViewOptions } from './useCustomViewOptions';
import { useBuildUser } from './useBuildUser';
import { useFeatureTogglesContext } from '../../../context/featureToggles';
import { useWorldsContext } from '../../../context/worlds';

import './build-user.css';

export type BuildUserData = Omit<IUserData, 'homeLocation' | 'zone'> & { homeLocationId: string | null, zoneId: string | null };

export interface IProps<RequestParams> {
  showPopup: boolean,
  handleClose: () => void,
  handleSave: () => void,
  canEditEmail: boolean,
  saveRequest: (params: RequestParams) => Promise<{ success: boolean }>,
  initialUser: {
    id?: string,
    email: string,
    role: UserRole,
    homeLocation: IHomeLocation | null,
    group: Group,
    zone: Zone | null,
    worldIds: World['id'][] | null[]
  },
  getRequestParams: (user: BuildUserData) => RequestParams,
  header: string,
  subheader: string,
  modalClass: string,
  getSaveDisabled: (user: BuildUserData, worlds: World[]) => boolean
}

const ModalLoading = styled(Loading)`
  & .loading-spinner {
    margin: 100px 0;
    position: relative;
  }
`;
ModalLoading.displayName = 'ModalLoading';

const CustomViewLoading = styled(Loading)`
  height: 150px;
  & .loading-spinner {
    position: relative;
    top: 20px;
    width: auto;
    text-align: left;
    margin-left: 50px;
  }
`;
CustomViewLoading.displayName = 'CustomViewLoading';

const TableRow = styled.div`
  display: table-row;
`;

const TableItem = styled.div`
  display: table-cell;
  padding: 0.8rem;
`;

const TableTitle = styled(TableItem)`
  font-weight: 600;
`;

const Table = styled.div`
  display: table;
  overflow-y: auto;
  max-height: 380px;
`;

const ScrollContainer = styled.div`
  overflow-y: auto;
  max-height: 380px;
`;

const Input = styled.input<{ valid: boolean }>`
  border: 1px solid #8f9699;
  border-radius: 3px;
  padding: 0.125rem;
  outline-color: #0072af;
  ${({ valid }) => {
    if (!valid) {
      return `
      border: 1px solid #c03b3b;

      &:focus {
        outline-color: #c03b3b;
      }
      `;
    }
  }}
`;

/* istanbul ignore next */ /* remove istanbul ignore once tests migrated */
const CustomViewSelectionInput = styled.input`
  margin-left: 4px;
  margin-right: 16px;
  ${({ disabled }) => !disabled ? 'cursor: pointer;' : ''}
`;
CustomViewSelectionInput.displayName = 'input';

const CustomView = styled.div`
  display: flex;
  padding-top: 0.8rem;
`;

const CustomViewPickers = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid;
  border-radius: 4px;
  padding: 5px;
`;

const CustomViewTitle = styled(TableTitle)`
  vertical-align: top;
`;

/* istanbul ignore next */ /* remove istanbul ignore once tests migrated */
const CustomViewAnd = styled.div<{ disabled: boolean }>`
  font-style: italic;
  margin-left: 10px;
  color: #707070;
  padding: 0.15rem;
  ${({ disabled }) => disabled ? 'opacity: 0.3;' : ''}
`;

/* istanbul ignore next */ /* remove istanbul ignore once tests migrated */
const CustomViewPicker = styled(OptionPicker)`
  position: relative;
  & .autosuggest-dropdown-button__autosuggest {
    margin-bottom: 1rem;
  }
  ${({ disabled }) => !disabled ? 'cursor: pointer;' : ''}
` as any;
CustomViewPicker.displayName = 'OptionPicker';

const ZonesPickerSection = styled(CustomViewPickers)`
  margin-bottom: 0.5rem;
`;

const ValidationError = styled.span`
  padding-left: 1rem;
  color: #c03b3b;
  font-size: 0.875rem;
`;
ValidationError.displayName = 'ValidationError';

const FooterMessage = styled.span`
  white-space: nowrap;
  &::before {
    content: '*';
    display: inline-block;
    color: var(--color-red);
    margin-right: 0.25rem;
  }
`;

export function BuildUser<RequestParams>(props: IProps<RequestParams>) {
  const ns = 'userManagement';
  const ns2 = 'forms';
  const { t } = useTranslation([ns, ns2]);
  const { showPopup, initialUser } = props;
  const { worlds } = useWorldsContext();

  const {
    error,
    email,
    role,
    homeLocation,
    worldIds,
    emailValidationError,
    saving,
    customViewType,
    group,
    zone,
    onGroupChange,
    onZoneChange,
    onSave,
    onClose,
    onEmailChange,
    onBlur,
    onRoleChange,
    onHomeLocationChange,
    onWorldIdsChange,
    onCustomViewTypeChange
  } = useBuildUser({
    initialUser: {
      id: initialUser.id,
      email: initialUser.email,
      role: initialUser.role,
      homeLocation: initialUser.homeLocation,
      worldIds: initialUser.worldIds,
      group: initialUser.group,
      zone: initialUser.zone
    },
    worlds,
    handleSave: props.handleSave,
    handleCancel: props.handleClose,
    getSaveDisabled: props.getSaveDisabled,
    saveRequest: props.saveRequest,
    getRequestParams: props.getRequestParams
  });

  const validWorldIds = useMemo(() => worldIds ? worldIds.filter(id => worlds.find(world => world.id === id)) : worldIds, [worlds, worldIds]);

  const { groupOptions, zoneOptions, homeLocationOptions, optionsLoaded } = useCustomViewOptions({
    customViewType,
    worldIds: validWorldIds,
    showPopup
  });

  const mapHomeLocationsToOptions = useMemo(() => getHomeLocationOptionMapper(t, (t: TTypedTFunction) => t('ALL_HOME_LOCATIONS')), [t]);
  const mapGroupsToOptions = useMemo(() => getGroupOptionMapper(t, (t: TTypedTFunction) => t('ALL_GROUPS')), [t]);
  const mapZonesToOptions = useMemo(() => getZoneOptionMapper(t, (t: TTypedTFunction) => t('filters:SELECT_ZONE')), [t]);
  const isNotAdminOrManager = role !== 'administrator' && role !== 'manager';
  const customViewEnabled = isNotAdminOrManager && validWorldIds?.length === 1;
  const displayedHomeLocationSelection = customViewEnabled && customViewType === CustomViewType.groupHomeLocation ? homeLocation : nullHomeLocation;
  const displayedGroupSelection = customViewEnabled && customViewType === CustomViewType.groupHomeLocation ? group : null;
  const displayedZoneSelection = customViewEnabled && customViewType === CustomViewType.zone ? zone : nullZone;
  const currentHomeLocationOption = mapHomeLocationsToOptions([displayedHomeLocationSelection])[0];
  const currentGroupOption = mapGroupsToOptions([displayedGroupSelection])[0];
  const currentZoneOption = mapZonesToOptions([displayedZoneSelection])[0];

  const customViewTooltip = (
    <InfoPopover labelText='about custom view'>
      {t('EDIT_CUSTOM_VIEW_TOOLTIP', { ns: 'userManagement' })}
    </InfoPopover>
  );

  return (
    <div data-id={(!optionsLoaded && customViewEnabled) ? 'build-user' : 'build-user--ready'}>
      <ModalPopup
        show={showPopup}
        handleClose={onClose}
        header={props.header}
        subheader={props.subheader}
        classname={`${props.modalClass} build-user_modal`}
        closeDisabled={false}
        footer={<FormControls
          submitResult={{
            status: saving ? 'loading' : error ? 'error' : undefined,
            message: error || null
          }}
          submitDisabled={((!optionsLoaded && customViewEnabled)) || Boolean(emailValidationError) || props.getSaveDisabled({
            id: initialUser.id,
            email,
            role,
            homeLocationId: isNotAdminOrManager && homeLocation.id ? homeLocation.id : null,
            zoneId: isNotAdminOrManager && zone.id ? zone.id : null,
            group: isNotAdminOrManager ? group : null,
            worldIds: validWorldIds
          }, worlds)}
          onCancel={onClose}
          onSubmit={onSave}
          message={
            <FooterMessage>
              {t('REQUIRED_FIELDS', { ns: ns2 })}
            </FooterMessage>
          }
          mode='save'
        />}
      >
        <ScrollContainer>
          <Table data-id="build-user-modal">
            <TableRow data-id="email-row">
              <TableTitle data-id="email-title">
                {formatTitle(t('EMAIL_ADDRESS', { ns: 'userManagement' }))}
                {props.canEditEmail && <RequiredIcon />}
              </TableTitle>
              <div data-id="email-value">
                {props.canEditEmail ? <Input
                  valid={!emailValidationError}
                  type="text"
                  data-id="email-input"
                  value={email}
                  onBlur={onBlur}
                  onChange={onEmailChange}
                /> : <div data-id="email-text">
                  {email}
                </div>}
              </div>
              <ValidationError data-id="validation-error">{emailValidationError}</ValidationError>
            </TableRow>
            <TableRow data-id="role-row">
              <TableTitle data-id="role-title">
                <RoleTitleTooltip title={formatTitle(t('ROLE', { ns: 'userManagement' }))} />
              </TableTitle>
              <div data-id="role-value">
                <RoleSelection
                  role={role}
                  setRole={onRoleChange}
                />
              </div>
            </TableRow>
            {worlds?.length > 1 && <TableRow data-id="world-access-row">
              <TableTitle data-id="world-access-title">
                {t('WORLD_ACCESS', { ns: 'userManagement' })}
                <RequiredIcon />
                <InfoPopover labelText='about world access'>{t('TOOLTIP_WORLD_ACCESS', { ns: 'userManagement' })}</InfoPopover>
              </TableTitle>
              <div data-id="world-access-value">
                <CheckBoxSelection
                  key={`checkboxes-${role}`} // used to reset the checkboxes on role change, as initial checked ids changes for some users
                  initialCheckedIds={validWorldIds}
                  checkAllText={t('ALL_WORLDS', { ns: 'userManagement' })}
                  values={worlds}
                  onBoxesChecked={onWorldIdsChange}
                  disabled={role === 'administrator'}
                />
              </div>
            </TableRow>}
            <TableRow>
              <CustomViewTitle data-id="custom-view-title">
                <>{formatTitle(t('CUSTOM_VIEW', { ns: 'userManagement' }))}{customViewTooltip}</>
              </CustomViewTitle>
              <CustomViewLoading isLoading={!optionsLoaded && customViewEnabled} transparentOverlay={false}>
                <CustomView>
                  <CustomViewSelectionInput
                    id="groups-and-home-locations-custom-view"
                    name="groups-and-home-locations-custom-view"
                    aria-label="toggle groups and home locations custom view"
                    checked={customViewEnabled && customViewType === CustomViewType.groupHomeLocation}
                    type="radio"
                    disabled={!customViewEnabled}
                    onChange={() => onCustomViewTypeChange(CustomViewType.groupHomeLocation)} />
                  <CustomViewPickers>
                    <CustomViewPicker<IHomeLocation>
                      options={homeLocationOptions}
                      current={currentHomeLocationOption}
                      onSelect={onHomeLocationChange}
                      disabled={!customViewEnabled || customViewType !== CustomViewType.groupHomeLocation}
                      getSuggestionClasses={getHomeLocationSuggestionClasses}
                    />
                    <CustomViewAnd disabled={!customViewEnabled || customViewType !== CustomViewType.groupHomeLocation}>{t('AND', { ns: 'translation' }).toUpperCase()}</CustomViewAnd>
                    <CustomViewPicker<Group>
                      options={groupOptions}
                      current={currentGroupOption}
                      onSelect={onGroupChange}
                      disabled={!customViewEnabled || customViewType !== CustomViewType.groupHomeLocation}
                      getSuggestionClasses={getGroupSuggestionClasses}
                    />
                  </CustomViewPickers>
                </CustomView>
                <CustomView>
                  <CustomViewSelectionInput
                    id="zones-custom-view"
                    name="zones-custom-view"
                    aria-label="toggle zones custom view"
                    checked={customViewEnabled && customViewType === CustomViewType.zone}
                    type="radio"
                    disabled={!customViewEnabled}
                    onChange={() => onCustomViewTypeChange(CustomViewType.zone)} />
                  <ZonesPickerSection>
                    <CustomViewPicker<Zone>
                      options={zoneOptions}
                      current={currentZoneOption}
                      onSelect={onZoneChange}
                      disabled={!customViewEnabled || customViewType !== CustomViewType.zone}
                      getSuggestionClasses={getZoneSuggestionClasses}
                    />
                  </ZonesPickerSection>
                </CustomView>
              </CustomViewLoading>
            </TableRow>
          </Table>
        </ScrollContainer>
      </ModalPopup>
    </div>
  );
}
