import { TableName } from '../../components/data-table/lib';
import { ChartName, NetworkChartName } from '../../containers/core/device/compositions/performance/charts/lib/chartNames';
import { request, RequestInitWithRetry } from '../../lib/request';
import { DeviceEventName } from '../core/eventsTypes';
import { AlarmEnabled, AlarmName, AlarmStatusEnabledFieldNames, AlarmStatusEnabledSettings, AlarmStatusThresholdFieldNames, AlarmStatusThresholdSettings, AlarmThresholds, AlertStatus, AlertStatusEnabledFieldName, AlertStatusEnabledSettings } from './alertConstants';

export enum Identifier {
  assetTag = 'assetTag',
  IMEI = 'imei',
  serialNumber = 'serialNumber'
}

export function getAlarmStatusField(alarm: AlarmName, field: AlarmEnabled): AlarmStatusEnabledFieldNames;
export function getAlarmStatusField(alarm: AlarmName, field: AlarmThresholds): AlarmStatusThresholdFieldNames;
export function getAlarmStatusField(alarm: AlarmName, field: AlarmEnabled | AlarmThresholds): AlarmStatusThresholdFieldNames | AlarmStatusEnabledFieldNames {
  if (field === 'Enabled') {
    return `${alarm}${field}` as AlarmStatusEnabledFieldNames;
  } else {
    return `${alarm}${field}` as AlarmStatusThresholdFieldNames;
  }
}

export function getAlertEnabledField(status: AlertStatus): AlertStatusEnabledFieldName | undefined {
  if (status === 'deviceUserExperienceDropStatus') {
    return `${status}Enabled` as AlertStatusEnabledFieldName;
  }
  if (status === 'deviceTimeErrorStatus') {
    return;
  }
  return status.replace('Status', 'Enabled') as AlertStatusEnabledFieldName;
}

export function isAlertEnabled(status: AlertStatus, worldSettings: WorldSettings): boolean {
  const key = getAlertEnabledField(status);
  return worldSettings[key] ?? true;
}

export const getConfig = async (brand: string, url: string) => {
  const params = {
    path: '/api/configuration/getConfiguration',
    qs: { brand, url },
    method: 'GET',
    internalQuery: true
  };

  return request(params);
};

export interface WorldSettings extends AlarmStatusEnabledSettings, AlarmStatusThresholdSettings, AlertStatusEnabledSettings {
  averageDischargeYellow: number,
  batteryHealthStatusRed: number,
  batteryHealthStatusYellow: number,
  deviceLocationDistanceRed: number,
  deviceLocationDistanceYellow: number,
  deviceLocationTimeRed: number,
  deviceLocationTimeYellow: number,
  deviceUserExperienceDropStatusPeriod: number,
  deviceUserExperienceDropStatusRed: number,
  deviceUserExperienceDropStatusYellow: number,
  deviceUserExperienceLowPowerPeriod: number,
  deviceUserExperienceLowPowerRed: number,
  deviceUserExperienceLowPowerYellow: number,
  deviceUserExperienceRebootPeriod: number,
  deviceUserExperienceRebootRed: number,
  deviceUserExperienceRebootYellow: number,
  deviceUtilisationIdleRed: number,
  deviceUtilisationIdleYellow: number,
  deviceUtilisationOutOfContactRed: number,
  deviceUtilisationOutOfContactYellow: number,
  batteryStatusEnabled: boolean,
  deviceIdentifier: Identifier,
  useMetricDistances: boolean,
  smartGroupingHour: number | null,
  smartGroupingTimeZone: string | null,
  androidLocationCollectionEnabled: boolean,
  windowsLocationCollectionEnabled: boolean,
  androidDropDetectionEnabled: boolean
}

export interface UserSettings {
  lastWhatsNewId?: number,
  chartEventFilters: ChartEventFilters,
  tableEventFilters: TableEventFilters,
  tablePageSizes: TablePageSizes
}

export interface BatteryTypeSettings {
  batteryType: string,
  cycleCountYellow: number,
  cycleCountRed: number
}

export type FeatureToggles = string[];
export type LanguageAndLocale = {
  language: string,
  locale: string
};

export type LastWhatsNew = {
  lastWhatsNewId: number
};

export const getWorldSettings = () => {
  return async (options: RequestInitWithRetry): Promise<WorldSettings> => {
    const params = {
      path: '/api/configuration/getWorldSettings',
    };
    return request(params, options);
  };
};

type MutationResponse = {
  success: boolean
};

export function setWorldSettingsAction(options: RequestInitWithRetry) {
  return async (data: WorldSettings): Promise<MutationResponse> => {
    const input = {
      path: `/api/configuration/setWorldSettings`,
      method: 'POST',
      body: data
    };
    return request(input, options);
  };
}

export function setBatteryTypeSettingsAction(options: RequestInitWithRetry) {
  return async (data: BatteryTypeSettings[]): Promise<MutationResponse> => {
    const input = {
      path: `/api/configuration/setBatteryTypeSettings`,
      method: 'POST',
      body: data
    };
    return request(input, options);
  };
}

export const getUserSettings = () => {
  return async (options: RequestInitWithRetry): Promise<UserSettings> => {
    const params = {
      path: '/api/configuration/getUserSettings',
    };
    return request(params, options);
  };
};

export const getBatteryTypeSettings = () => {
  return async (options: RequestInitWithRetry): Promise<BatteryTypeSettings[]> => {
    const params = {
      path: '/api/configuration/getBatteryTypeSettings',
    };
    return request(params, options);
  };
};

export const getFeatureToggles = (userId: string) => {
  return async (options: RequestInitWithRetry): Promise<FeatureToggles> => {
    const params = {
      path: '/api/configuration/getFeatureToggles',
      qs: { userId }
    };
    return request(params, options);
  };
};

export const getLanguageAndLocale = () => {
  return async (options: RequestInitWithRetry): Promise<LanguageAndLocale> => {
    const params = {
      path: '/api/configuration/getLanguageAndLocale'
    };
    return request(params, options);
  };
};

export function setLanguageAndLocaleAction(options: RequestInitWithRetry) {
  return async (data: LanguageAndLocale): Promise<MutationResponse> => {
    const params = {
      path: '/api/configuration/setLanguageAndLocale',
      method: 'POST',
      body: data
    };
    return request(params, options);
  };
}

export interface IDeviceToken {
  deviceToken: {
    data?: Buffer
  }
}

export const getDeviceToken = () => {
  return async (options: RequestInitWithRetry): Promise<IDeviceToken> => {
    const params = {
      path: '/api/worlds/currentWorldDeviceToken',
      method: 'GET'
    };
    return request(params, options);
  };
};

export const getModels = () => {
  return (options: RequestInitWithRetry): Promise<string[]> => {
    const params = {
      path: '/api/configuration/getModels',
      method: 'GET'
    };
    return request(params, options);
  };
};

export const getManufacturers = () => {
  return (options: RequestInitWithRetry): Promise<string[]> => {
    const params = {
      path: '/api/configuration/getManufacturers',
      method: 'GET'
    };
    return request(params, options);
  };
};

export interface IGroup {
  name: string
}
export interface IGroups {
  groups: {
    nodes: IGroup[]
  }
}

export const getGroups = () => {
  return (options: RequestInitWithRetry): Promise<IGroups> => {
    const params = {
      path: '/api/configuration/getGroups',
      method: 'GET'
    };
    return request(params, options);
  };
};

export interface IHomeLocation {
  id: string,
  name: string
}

export const getHomeLocations = () => {
  return (options: RequestInitWithRetry): Promise<IHomeLocation[]> => {
    const params = {
      path: '/api/configuration/getHomeLocations',
      method: 'GET'
    };
    return request(params, options);
  };
};

export interface IZone {
  id: string,
  name: string,
  groups1: string[] | null,
  groups2: string[] | null
}

export const getZones = () => {
  return (options: RequestInitWithRetry): Promise<IZone[]> => {
    const params = {
      path: '/api/configuration/getZones',
      method: 'GET'
    };
    return request(params, options);
  };
};

export type EventFilters = {
  [event in DeviceEventName]?: boolean
};

export type ChartEventFilters = {
  [name in ChartName]?: EventFilters
};

export type NetworkChartEventFilters = {
  [name in NetworkChartName]?: EventFilters
};


export type SetChartEventFiltersRequest = (data: ChartEventFilters | NetworkChartEventFilters) => Promise<{ set: boolean }>;
export function setChartEventFiltersAction(options: RequestInitWithRetry): SetChartEventFiltersRequest {
  return async (data: ChartEventFilters | NetworkChartEventFilters): Promise<{ set: boolean }> => {
    const params = {
      path: '/api/configuration/setChartEventFilters',
      method: 'POST',
      body: data
    };
    return request(params, options);
  };
}

export type TableEventFilters = {
  [name in TableName]?: EventFilters
};

export type SetTableEventFiltersRequest = (data: TableEventFilters) => Promise<{ set: boolean }>;
export function setTableEventFiltersAction(options: RequestInitWithRetry): SetTableEventFiltersRequest {
  return async (data: TableEventFilters): Promise<{ set: boolean }> => {
    const params = {
      path: '/api/configuration/setTableEventFilters',
      method: 'POST',
      body: data
    };
    return request(params, options);
  };
}

export type TablePageSizes = {
  [name in TableName]?: number
};

export type SetTablePageSizesRequest = (data: TablePageSizes) => Promise<{ set: boolean }>;
export function setTablePageSizesAction(options: RequestInitWithRetry): SetTablePageSizesRequest {
  return async (data: TablePageSizes): Promise<{ set: boolean }> => {
    const params = {
      path: '/api/configuration/setTablePageSizes',
      method: 'POST',
      body: data
    };
    return request(params, options);
  };
}

export function setLastWhatsNewAction(options: RequestInitWithRetry) {
  return async (data: LastWhatsNew): Promise<MutationResponse> => {
    const params = {
      path: '/api/configuration/setLastWhatsNew',
      method: 'POST',
      body: data
    };
    return request(params, options);
  };
}
