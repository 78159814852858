import * as React from 'react';
import { IntensityMap } from './shared/intensityMap';
import { getGeotileLocation } from './shared/utils';
import { ArrayElement } from './shared/types';
import { NoServiceResponse, getNoServiceIntensity } from '../../services/core/maps';
import { TooltipContentFC } from './shared/overlayLayer';
import { useTranslation } from '@lib/useTypedTranslation';

export type NoServiceDataPoint = ArrayElement<NoServiceResponse>;

type NoServiceMapProps = {
  groups?: string[],
  homeLocations?: string[],
  deviceId?: string,
  zoneId?: string,
  timePeriod: number
};

export const NoServiceTooltipContent: TooltipContentFC<NoServiceDataPoint> = props => {
  const { t } = useTranslation(['maps', 'translation']);

  if (props.dataPoint.noServiceCount === undefined) {
    return <><p>{t('LOADING', { ns: 'translation' })}</p></>;
  }

  return <>
    <p>{t('LOCATION', { ns: 'maps' })}: {getGeotileLocation(props.dataPoint.geotile)}</p>
    <p>{t('NO_SERVICE_INTENSITY', { ns: 'maps' })}: {`${props.dataPoint.noServiceCount} (${props.dataPoint.percentage}%)`}</p>
    <p>{t('TOTAL_COUNT', { ns: 'maps' })}: {props.dataPoint.totalCount}</p>
  </>;
};

export const NoServiceMap: React.FC<NoServiceMapProps> = props => {
  return <IntensityMap<NoServiceDataPoint>
    fetchMapData={(boundingLatLong: string, geotilePrecision: number) => {
      return getNoServiceIntensity({
        boundingLatLong,
        geotilePrecision,
        groups: props.groups,
        homeLocations: props.homeLocations,
        zoneId: props.zoneId,
        deviceId: props.deviceId,
        timePeriod: props.timePeriod
      });
    }}
    TooltipContent={NoServiceTooltipContent}
    groups={props.groups}
    homeLocations={props.homeLocations}
    zoneId={props.zoneId}
    deviceId={props.deviceId}
  />;
};
