import * as React from 'react';
import styled from 'styled-components';
import { useForm } from 'react-hook-form';
import { IItem, SelectInput } from '../../components/controls/selectInput';
import { Button } from '../../components/buttons/button';
import { ButtonColours } from '../app/themes';
import { addWorldAction, AddWorldData, dpas, eulas, partners } from '../../services/universeAdmin/worlds';
import { useNonWorldAction } from '../../lib/useNonWorldAction';
import { RequiredIcon } from '../../components/icons/requiredIcon';
import { useState } from 'react';
import { useWait } from '../../lib/wait';

export enum ElemezProducts {
  'Elemez Battery Essentials' = 'elemez-battery-essentials',
  'Elemez Core' = 'elemez-core'
}

export enum PanasonicProducts {
  'Smart Battery Warranty' = 'panasonic-smart-battery-warranty',
  'Smart Battery Monitor' = 'panasonic-smart-battery-monitor',
  'Smart Service' = 'panasonic-smart-service'
}

export enum Product {
  'Elemez Battery Essentials' = 'elemez-battery-essentials',
  'Elemez Core' = 'elemez-core',
  'Smart Battery Warranty' = 'panasonic-smart-battery-warranty',
  'Smart Battery Monitor' = 'panasonic-smart-battery-monitor',
  'Smart Service' = 'panasonic-smart-service'
}

function getProducts(partner: AddWorldFormData['partner']): IItem<Product>[] {
  if (partner === 'none' || !partner) {
    return (Object.keys(Product) as (keyof typeof Product)[])
      .map(product => ({
        label: product,
        value: Product[product]
      }));
  }

  if (partner.includes('panasonic')) {
    return (Object.keys(PanasonicProducts) as (keyof typeof Product)[])
      .map(product => ({
        label: product,
        value: Product[product]
      }));
  }

  return (Object.keys(ElemezProducts) as (keyof typeof Product)[])
    .map(product => ({
      label: product,
      value: Product[product]
    }));
}

type AddWorldFormData = Omit<AddWorldData, 'partner' | 'requiredEULA' | 'requiredDPA'> & {
  partner: typeof partners[number] | 'none',
  requiredEULA: typeof eulas[number] | 'none',
  requiredDPA: typeof dpas[number] | 'none'
};

export function AddWorld() {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitResult, setSubmitResult] = useState<string>(null);
  const { wait } = useWait();
  const addWorld = useNonWorldAction(addWorldAction);
  const { formState: { isDirty, isValid }, register, reset, handleSubmit, setValue, watch } = useForm<AddWorldFormData>({
    defaultValues: {
      type: null,
      partner: null,
      retentionPeriod: null,
      featureToggles: [],
      product: null,
      requiredDPA: null,
      requiredEULA: null
    },
    mode: "onChange"
  });

  const onSubmit = async (data: AddWorldFormData) => {
    const partner = data.partner === 'none' ? null : data.partner;
    const requiredEULA = data.requiredEULA === 'none' ? null : data.requiredEULA;
    const requiredDPA = data.requiredDPA === 'none' ? null : data.requiredDPA;
    setIsSubmitting(true);
    try {
      await addWorld({ ...data, partner, requiredEULA, requiredDPA });
      setSubmitResult('Successfully Created');

      await wait(2000);

      setSubmitResult('');
      reset();
    } catch (err) {
      setSubmitResult('An Error Occurred');

      await wait(2000);

      setSubmitResult('');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <FormContainer>
      <Form onSubmit={handleSubmit(onSubmit)}>

        <FormField>
          <TextInput id='name' {...register('name', { required: true })} placeholder="Name" />
          <TextLabel htmlFor='name'>Name<RequiredIcon /></TextLabel>
        </FormField>

        <FormField>
          <Title>Type<RequiredIcon/></Title>
          <Row>
            <NestedLabel htmlFor='production'>Production</NestedLabel>
            <Radio id='production' type='radio' value='production' name='type' {...register('type', { required: true })} />
          </Row>
          <Row>
            <NestedLabel htmlFor='trial'>Trial</NestedLabel>
            <Radio id='trial' type='radio' value='trial' name='type' {...register('type', { required: true })} />
          </Row>
        </FormField>

        <FormField>
          <Label>Partner<RequiredIcon/></Label>
          <SelectInput<AddWorldFormData['partner']>
            selectedValue={watch('partner')}
            register={register('partner', { required: true })}
            items={[
              { label: 'none', value: 'none' },
              ...partners.map((partner) => ({ label: partner, value: partner }))
            ]}
            label='Partner'
            onItemSelected={
              /* istanbul ignore next */
              (value) => { setValue('partner', value, { shouldDirty: true }); setValue('product', null, { shouldDirty: true }); }
            } />
        </FormField>

        <FormField>
          <Label>Retention Period<RequiredIcon/></Label>
          <SelectInput
            selectedValue={watch('retentionPeriod')?.toString()}
            items={[
              { label: '1', value: '1' },
              { label: '3', value: '3' },
              { label: '14', value: '14' }
            ]}
            label='Retention Period'
            register={register('retentionPeriod', { valueAsNumber: true, required: true })}
            onItemSelected={
              /* istanbul ignore next */
              value => { setValue('retentionPeriod', parseInt(value) as 1 | 3 | 14, { shouldDirty: true }); }
            }
          />
        </FormField>

        <FormField>
          <Label>Product<RequiredIcon /></Label>
          <SelectInput<Product>
            selectedValue={watch('product')}
            items={getProducts(watch('partner'))}
            register={register('product', { required: true })}
            label='Product'
            onItemSelected={
              /* istanbul ignore next */
              value => setValue('product', value, { shouldDirty: true })
            } />
        </FormField>

        <FormField>
          <Label>Required EULA<RequiredIcon /></Label>
          <SelectInput<AddWorldData['requiredEULA'] | 'none'>
            selectedValue={watch('requiredEULA')}
            items={[
              { label: 'none', value: 'none' },
              ...eulas.map((eula) => ({ label: eula, value: eula }))
            ]}
            label='Required EULA'
            register={register('requiredEULA', { required: true })}
            onItemSelected={
              /* istanbul ignore next */
              value => { setValue('requiredEULA', value, { shouldDirty: true }); }
            }
          />
        </FormField>

        <FormField>
          <Label>Required DPA<RequiredIcon /></Label>
          <SelectInput<AddWorldData['requiredDPA'] | 'none'>
            selectedValue={watch('requiredDPA')}
            items={[
              { label: 'none', value: 'none' },
              ...dpas.map((dpas) => ({ label: dpas, value: dpas }))
            ]}
            label='Required DPA'
            register={register('requiredDPA', { required: true })}
            onItemSelected={
              /* istanbul ignore next */
              value => { setValue('requiredDPA', value, { shouldDirty: true }); }
            }
          />
        </FormField>

        <ButtonsContainer>
          <SubmitButton type="submit" colour={ButtonColours.green} text='Add World' disabled={!isDirty || !isValid || isSubmitting} />
          <Button type="button" colour={ButtonColours.grey} text='Clear Changes' disabled={isSubmitting || !isDirty} onClick={() => { reset(); setSubmitResult(null); }} />
          <Status><MessageWrapper successful={submitResult?.toLowerCase().includes('success')} failed={submitResult?.toLowerCase().includes('error')}>{submitResult || ''}</MessageWrapper></Status>
        </ButtonsContainer>

      </Form>
    </FormContainer>
  );
}

const FormContainer = styled.div`
  margin: 2rem;
  display: flex;
  justify-content: center;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  background-color: white;
  width: 40%;
  border-radius: 0.5rem;
  border: 3px solid white;

  @media(max-width: 950px) {
    width: 95%;
  }
`;

const FormField = styled.div`
  position: relative;
  padding: 15px 0 0;
  margin: 10px 20px;
  display: flex;
  flex-direction: column;
`;

const TextLabel = styled.label`
  position: absolute;
  top: 0;
  display: block;
  transition: 0.2s;
  font-size: 12px;
  color: #9b9b9b;
`;

const TextInput = styled.input`
  width: 30%;
  border: 1px solid whitesmoke;
  border-radius: 3px;
  padding: 3px;
  border-bottom: 2px solid black;
  background-color: #f7f7f7;

  font-family: inherit;
  width: 55%;
  border: 0;
  border-bottom: 1px solid #d2d2d2;
  outline: 0;
  font-size: 16px;
  color: #212121;
  padding: 7px 0;
  background: transparent;
  transition: border-color 0.2s;

  &:placeholder-shown ~ label {
    font-size: 16px;
    cursor: text;
    top: 20px;
  }

  &:focus {
    border-bottom: 2px solid darkblue;
    outline: none;
    padding-bottom: 6px;
  }

  &:focus ~ label {
    position: absolute;
    top: 0;
    display: block;
    transition: 0.2s;
    font-size: 12px;
    color: darkblue;
  }

  &::placeholder {
    color: transparent;
  }

  @media(max-width: 900px) {
    width: 100%;
  }
`;

const NestedLabel = styled.label`
  font-size: 14px;
  cursor: text;
  color: #555151;
  margin-right: 0.5rem;
  margin-bottom: 0;
`;

const Label = styled.label`
  font-size: 16px;
  cursor: text;
  color: #9b9b9b;
  margin-right: 0.5rem;
  margin-bottom: 0;
`;

const Title = styled.div`
  font-size: 16px;
  cursor: text;
  color: #9b9b9b;
  margin-right: 0.5rem;
  margin-bottom: 0;
`;

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: center;

  & button {
    width: 9rem;
    margin: 0.4rem;
  }
`;

const SubmitButton = styled(Button)`
  ${({ theme }) => { return `border: 1px solid ${theme.buttons[ButtonColours.green].light};`; }}

  ${({ theme }) => {
    return `
      &:disabled {
        border: 1px solid ${theme.buttons[ButtonColours.green].light};
      }
      
      &:hover {
        border: 1px solid ${theme.buttons[ButtonColours.green].dark};
      }`;
  }}
`;

const Row = styled.div`
  display: flex;
`;

const Radio = styled.input`
  &:hover {
    cursor: pointer;
  };
`;

const CheckBoxInput = styled.input`
  align-self: center;
`;

const CheckBoxLabel = styled.label`
  margin-bottom: 0;
  display: flex;
  align-items: center;

  &::before {
    margin-right: 15px !important;
  }
`;

const CheckBoxParent = styled.div`
  display: flex;
  margin-bottom: 5px;

  &:focus-within label::before {
    outline: auto;
  }
`;

const getSubmitStyles = (successful: boolean, failed: boolean) => {
  if (successful) {
    return `color: #298d3a;
    background-color: #298d3a22;`;
  } else if (failed) {
    return `color: #520303c2;
    background-color: #f77878c9;`;
  } else {
    return 'opacity: 1;';
  }
};

const MessageWrapper = styled.div<{ successful: boolean, failed: boolean }>`
  font-size: 0.846rem;
  vertical-align: middle;
  margin: 0.4rem;
  padding: 0.375rem 1.25rem;
  text-align: center;
  white-space: nowrap;
  border-radius: 5px;

  @media (max-width: 992px) { 
    margin-right: 0.996rem;
  }

  ${({ successful, failed }) => getSubmitStyles(successful, failed)};
`;

const Status = styled.div`
  flex-grow: 1;
  display: flex;
`;
