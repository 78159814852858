import { flatten } from 'lodash';

import { DeviceEventName } from '../../../../../../services/core/eventsTypes';
import { IEventFilter, IEventGroupNames, IEventFilterGroups } from './edit-events';
import { WithOptional } from '../../../../../../lib/typeUtils';
import { ChartName, NetworkChartName } from '../charts/lib/chartNames';
import { TableName } from '../../../../../../components/data-table/lib';

export type DeviceEvents = {
  [event in DeviceEventName]: IDeviceEventFilter
};

// deviceDropped events are not shown for windows devices
// batteryChanged events are not shown if batteryChangedEvents feature-toggle disabled
export type DeviceEventFilters = WithOptional<{
  [event in DeviceEventName]: boolean;
}, 'deviceDropped' | 'batteryChanged'>;

export type DeviceChartEventsFilterState = {
  [chartName in ChartName]: IDeviceEventFilterState
};

export type DeviceNetworkChartEventsFilterState = {
  [chartName in NetworkChartName]: IDeviceEventFilterState
};

export type DeviceTableEventsFilterState = {
  [tableName in TableName]: IDeviceEventFilterState
};

export interface IDeviceEventFilterState {
  appliedEventFilters: DeviceEventFilters,
  currentEventFilters: DeviceEventFilters
}

interface IDeviceEventFilter extends Omit<IEventFilter, 'name'> {
  name: DeviceEventName
}

export const deviceEventFilterGroupNames: IEventGroupNames = {
  applications: 'APPLICATIONS_GROUP',
  power: 'POWER_GROUP',
  userExperience: 'USER_EX_GROUP',
  time: 'TIME_GROUP',
  battery: 'BATTERY_GROUP',
  network: 'NETWORK'
};

export const deviceEvents: DeviceEvents = {
  applicationInstalled: {
    name: 'applicationInstalled',
    displayName: 'APPLICATION_INSTALLED',
    icon: 'device-event-icon fas fa-plus event-colour-blue'
  },
  applicationUninstalled: {
    name: 'applicationUninstalled',
    displayName: 'APPLICATION_UNINSTALLED',
    icon: 'device-event-icon fas fa-minus event-colour-red'
  },
  applicationUpdated: {
    name: 'applicationUpdated',
    displayName: 'APPLICATION_UPDATED',
    icon: 'device-event-icon fas fa-sync event-colour-blue'
  },
  deviceOnPower: {
    name: 'deviceOnPower',
    displayName: 'DEVICE_ON_POWER',
    icon: 'device-event-icon fas fa-plug custom-rotate-45 event-colour-green'
  },
  deviceOffPower: {
    name: 'deviceOffPower',
    displayName: 'DEVICE_OFF_POWER',
    icon: 'device-event-icon fas fa-plug custom-rotate-315 event-colour-red'
  },
  deviceLowPower: {
    name: 'deviceLowPower',
    displayName: 'DEVICE_LOW_POWER',
    icon: 'device-event-icon fas fa-battery-quarter fa-rotate-270 event-colour-red'
  },
  deviceRebooted: {
    name: 'deviceRebooted',
    displayName: 'DEVICE_REBOOTED',
    icon: 'device-event-icon fas fa-power-off event-colour-grey'
  },
  deviceDropped: {
    name: 'deviceDropped',
    displayName: 'DEVICE_DROP',
    icon: 'device-event-icon fas custom-icon custom-icon-drop event-colour-grey'
  },
  timeError: {
    name: 'timeError',
    displayName: 'TIME_ERROR',
    icon: 'device-event-icon fas custom-icon custom-icon-time-error event-colour-red'
  },
  timeRecovery: {
    name: 'timeRecovery',
    displayName: 'TIME_RECOVERY',
    icon: 'device-event-icon far fa-clock event-colour-grey'
  },
  batteryChanged: {
    name: 'batteryChanged',
    displayName: 'BATTERY_SWAP',
    icon: 'device-event-icon fas custom-icon custom-icon-battery-changed event-colour-grey'
  },
  networkChanged: {
    name: 'networkChanged',
    displayName: 'NETWORK_CHANGED',
    icon: 'device-event-icon fas custom-icon custom-icon-network-changed event-colour-grey'
  },
  networkAvailable: {
    name: 'networkAvailable',
    displayName: 'NETWORK_AVAILABLE',
    icon: 'device-event-icon fas custom-icon custom-icon-network-available event-colour-green'
  },
  networkLost: {
    name: 'networkLost',
    displayName: 'NETWORK_LOST',
    icon: 'device-event-icon fas custom-icon custom-icon-network-lost event-colour-red'
  },
  bearerChanged: {
    name: 'bearerChanged',
    displayName: 'BEARER_CHANGED',
    icon: 'device-event-icon fas custom-icon custom-icon-bearer-changed event-colour-grey'
  },
  simChanged: {
    name: 'simChanged',
    displayName: 'SIM_CHANGED',
    icon: 'device-event-icon fas custom-icon custom-icon-sim-changed event-colour-grey'
  }
};

export const deviceEventFilterGroups: IEventFilterGroups = new Map([
  ['applications', [
    deviceEvents.applicationInstalled,
    deviceEvents.applicationUninstalled,
    deviceEvents.applicationUpdated
  ]],
  ['power', [
    deviceEvents.batteryChanged,
    deviceEvents.deviceOnPower,
    deviceEvents.deviceOffPower,
    deviceEvents.deviceLowPower
  ]],
  ['userExperience', [
    deviceEvents.deviceRebooted,
    deviceEvents.deviceDropped
  ]],
  ['time', [
    deviceEvents.timeError,
    deviceEvents.timeRecovery
  ]],
  ['network', [
    deviceEvents.networkAvailable,
    deviceEvents.networkChanged,
    deviceEvents.networkLost,
    deviceEvents.bearerChanged,
    deviceEvents.simChanged
  ]],
]);

export const deviceEventFiltersArray = flatten(Array.from(deviceEventFilterGroups.values()));
