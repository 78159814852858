import React from 'react';
import { useTranslation } from '@lib/useTypedTranslation';
import styled from 'styled-components';

import { ChipButton } from '../controls/chipButton';
import { OptionPicker } from '../controls/optionPicker';
import { IHomeLocation } from '../../services/config/config';
import {
  Group,
  Option,
  getHomeLocationSuggestionClasses,
  getGroupSuggestionClasses,
  Zone
} from '../controls/optionPickerLib';
import { Theme } from '../../containers/app/themes';
import { Filter } from './useFilterPicker';
import { TimePeriod } from './useTimePeriodPicker';

const getPickerButtonRender = (active: boolean) => {
  return (text: string, disabled: boolean, open: boolean) => {
    return (
      <ChipButton
        text={text}
        active={active}
        disabled={disabled}
        dropdownOpen={open}
      />
    );
  };
};

const Title = styled.div`
    font-weight: ${({ theme }: { theme: Theme}) => theme.font.weights.semiBold};
`;

const ParentContainer = styled.div`
  display: flex;
  padding: 0 2.5rem 1rem;
  justify-content: center;
  flex-wrap: wrap;
`;

const ChildrenContainer = styled.div`
  display: flex;
  align-items: center;

  &:not(:last-child) {
    margin-right: 2rem;
  }

  & > *:not(:last-child) {
    margin-right: 0.75rem;
  }
`;

interface ITimePeriodPickerProps {
  timePeriod: Option<TimePeriod>,
  timePeriodOptions: Option<TimePeriod>[],
  handleTimePeriodSelect: (selected: Option<TimePeriod>) => void
}

export const TimePeriodPicker: React.FC<ITimePeriodPickerProps> = props => {
  const { t } = useTranslation('filters');
  const {
    timePeriod,
    timePeriodOptions,
    handleTimePeriodSelect
  } = props;

  return <>
    <Title>{t('TIME_PERIOD')}</Title>
    <OptionPicker<TimePeriod>
      id="timePeriodPicker"
      options={timePeriodOptions}
      current={timePeriod}
      onSelect={handleTimePeriodSelect}
      renderButton={getPickerButtonRender(true)}
      selectText={t('TIME_PERIOD')}
      disableInput
    />
  </>;
};

export interface TimePeriodAndFilterPickerProps<AdditionalFilterTypes = any> {
  filter: Filter,
  homeLocationOptions: Option<IHomeLocation>[],
  groupOptions: Option<Group>[],
  zoneOptions: Option<Zone>[],
  allFilterActive: boolean,
  groupActive: boolean,
  homeLocationActive: boolean,
  zoneActive: boolean,
  homeLocationPickerVisible: boolean,
  zonePickerVisible: boolean,
  handleFilterReset: () => void,
  handleHomeLocationSelect: (selected: Option<IHomeLocation>) => void,
  handleGroupSelect: (selected: Option<Group>) => void,
  handleZoneSelect: (selected: Option<Zone>) => void,
  timePeriod: Option<TimePeriod>,
  timePeriodOptions: Option<TimePeriod>[],
  handleTimePeriodSelect: (selected: Option<TimePeriod>) => void,
  className?: string,
  additionalFilters?: {
    id: string,
    options: Option<AdditionalFilterTypes>[],
    handleFilterSelect: (selected: Option<AdditionalFilterTypes>) => void,
    current: Option<AdditionalFilterTypes>,
    active: boolean,
    selectText: string,
    title?: string
  }[]
}

export function TimePeriodAndFilterPicker<AdditionalFilterTypes>(props: TimePeriodAndFilterPickerProps<AdditionalFilterTypes>) {
  const { t } = useTranslation('filters');

  const {
    filter,
    homeLocationOptions,
    groupOptions,
    zoneOptions,
    allFilterActive,
    groupActive,
    homeLocationActive,
    zoneActive,
    homeLocationPickerVisible,
    zonePickerVisible,
    handleFilterReset,
    handleHomeLocationSelect,
    handleGroupSelect,
    handleZoneSelect,
    timePeriod,
    timePeriodOptions,
    handleTimePeriodSelect,
    className
  } = props;

  return (
    <ParentContainer className={className}>
      <ChildrenContainer>
        <TimePeriodPicker {...{
          timePeriod,
          timePeriodOptions,
          handleTimePeriodSelect
        }} />
      </ChildrenContainer>
      <ChildrenContainer>
        <Title>{t('FILTER_BY')}</Title>
        <ChipButton
          onClick={handleFilterReset}
          text={t('ALL_DEVICES')}
          active={allFilterActive}
        />
        {homeLocationPickerVisible && <OptionPicker<IHomeLocation>
          id="homeLocationPicker"
          options={homeLocationOptions}
          current={filter.homeLocation}
          onSelect={handleHomeLocationSelect}
          disabled={!homeLocationOptions.length}
          renderButton={getPickerButtonRender(homeLocationActive)}
          getSuggestionClasses={getHomeLocationSuggestionClasses}
          selectText={t('SELECT_HOME_LOCATION')}
        />}
        <OptionPicker<Group>
          id="groupPicker"
          options={groupOptions}
          current={filter.group}
          onSelect={handleGroupSelect}
          disabled={!groupOptions.length}
          renderButton={getPickerButtonRender(groupActive)}
          getSuggestionClasses={getGroupSuggestionClasses}
          selectText={t('SELECT_GROUP')}
        />
        {zonePickerVisible && <OptionPicker<Zone>
          id="zonePicker"
          options={zoneOptions}
          current={filter.zone}
          onSelect={handleZoneSelect}
          disabled={!zoneOptions.length}
          renderButton={getPickerButtonRender(zoneActive)}
          selectText={t('SELECT_ZONE')}
        />}
      </ChildrenContainer>
      {
        props.additionalFilters && props.additionalFilters?.map(additionalFilter => (
          <ChildrenContainer key={additionalFilter.id}>
            {additionalFilter.title && <Title>{additionalFilter.title}</Title>}
            <OptionPicker<AdditionalFilterTypes>
              id={additionalFilter.id}
              options={additionalFilter.options}
              current={additionalFilter.current}
              onSelect={additionalFilter.handleFilterSelect}
              renderButton={getPickerButtonRender(additionalFilter.active)}
              selectText={additionalFilter.selectText}
              disableInput
            />
          </ChildrenContainer>
        ))
      }
    </ParentContainer>
  );
}
