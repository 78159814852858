import React from 'react';
import styled from 'styled-components';

interface Props {
  header: React.ReactNode,
  subHeader?: React.ReactNode,
  noBorder?: boolean,
  children?: React.ReactNode,
  className?: string
}

const BorderCreator = styled.div<{ border: boolean }>`
  width: 100%;
  ${({ border }) => {
    if (border) {
      return `
        display: block;
        width: 100%;
        border-bottom: 1px solid #d7e2eb;
        margin-bottom: 1rem;
        `;
    }
  }}
`;
BorderCreator.displayName = 'BorderCreator';

const PageHeaderContents = styled.div`
  display: flex;
  margin: 1.5rem 2.5rem 0.75rem;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 600px) {
    margin: 1rem;
  }
`;
PageHeaderContents.displayName = 'PageHeaderContents';

const Subheader = styled.div`
  font-size: 0.875rem; /* 14px */
`;
Subheader.displayName = 'Subheader';

const Header = styled.h1`
  font-weight: 700;
  font-size: 1.125rem;  /* 18px */
  margin-bottom: 0;
`;
Header.displayName = 'Header';

const Children = styled.div`
  font-weight: 600;
  font-size: 0.9375rem; /* 15 px */
  margin-left: 20px;
`;
Children.displayName = 'Children';

export const PageHeader = ({ header, subHeader, noBorder, className, children }: Props) => {
  return (
    <BorderCreator border={!noBorder}>
      <PageHeaderContents className={className || ''}>
        <div>
          <Header data-id="page-header">
            {header}
          </Header>
          {subHeader &&
            <Subheader data-id="page-subheader">
              {subHeader}
            </Subheader>
          }
        </div>
        <Children data-id="page-header-children">{children}</Children>
      </ PageHeaderContents>
    </BorderCreator>
  );
};
