import React, { useMemo } from 'react';
import { useTranslation } from '@lib/useTypedTranslation';
import ReactTags, { Tag } from 'react-tag-autocomplete';
import { differenceBy } from 'lodash';
import { matchSorter } from 'match-sorter';

import './groupTagPicker.css';

export interface Props {
  groups: string[],
  suggestions: string[],
  setError: (error: string) => void,
  onGroupsChange: (groups: string[]) => void
}

export const MAX_GROUP_LENGTH = 32;

const mapGroupsToTags = (groups: string[]): Tag[] => groups.map((g) => ({ id: g, name: g }));

export const GroupTagPicker = (props: Props) => {
  const { groups, suggestions, setError, onGroupsChange } = props;
  const groupTags = useMemo(() => mapGroupsToTags(groups), [groups]);
  const suggestionTags = useMemo(() => mapGroupsToTags(suggestions), [suggestions]);
  const ns = 'editRecord';
  const { t } = useTranslation(ns);

  const onGroupInput = (_: string) => {
    setError('');
  };

  const onGroupAddition = (tag: Tag) => {
    const newGroup = tag.name.trim().toLowerCase();
    if (groups.includes(newGroup)) { return; }
    onGroupsChange([...groups, newGroup]);
  };

  const onGroupDelete = (index: number) => {
    onGroupsChange(groups.filter((_, i) => i !== index));
  };

  const groupSuggestionsTransform = (rawQuery: string, suggestions: Tag[]) => {
    const query = rawQuery.trim().toLowerCase();
    const unusedTags = differenceBy(suggestions, groupTags, 'name');
    const options = { threshold: matchSorter.rankings.CONTAINS, keys: ['name'] };
    return matchSorter(unusedTags, query, options);
  };

  const onGroupValidate = ({ name }: Tag) => {
    if (name.includes(',')) {
      setError(t('GROUP_VALIDATION_ERR_COMMAS', { ns }));
      return false;
    }
    if (name.trim().length > MAX_GROUP_LENGTH) {
      setError(t('GROUP_VALIDATION_ERR_LENGTH', { ns, maxLength: MAX_GROUP_LENGTH }));
      return false;
    }
    if (!name.trim()) {
      // no need for error message for empty group, but we mustn't add it to the tag list
      setError('');
      return false;
    }
    setError('');
    return true;
  };

  return (
    <div className='group-tag-picker'>
      <ReactTags
        tags={groupTags}
        suggestions={suggestionTags}
        onInput={onGroupInput}
        onDelete={onGroupDelete}
        onAddition={onGroupAddition}
        suggestionsTransform={groupSuggestionsTransform}
        onValidate={onGroupValidate}
        placeholderText={t('GROUP_PLACEHOLDER', { ns })}
        removeButtonText={t('GROUP_REMOVE', { ns })}
        minQueryLength={1}
        maxSuggestionsLength={6}
        autoresize={false}
        allowNew={true}
        allowBackspace={false}
      />
    </div>
  );
};
