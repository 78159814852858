import * as React from "react";
import { DataPoint } from "./types";
import { getColorFromIntensity, getGeotileBounds } from "./utils";
import { Rectangle, Tooltip } from "react-leaflet";

export type TileProps<TData extends DataPoint> = {
  dataPoint: Partial<TData>,
  tooltip: boolean,
  selected: boolean,
  discrete: boolean,
  tooltipContent: JSX.Element,
  select: () => void,
  deselect: () => void
};

export const Tile = <TData extends DataPoint>({
  dataPoint,
  tooltip,
  selected,
  discrete,
  tooltipContent,
  select,
  deselect
}: TileProps<TData>) => {
  const bounds = getGeotileBounds(dataPoint.geotile);

  const isSelected = !tooltip && (selected);

  const isClickable = dataPoint.percentage !== undefined;

  const fillColor = getColorFromIntensity(dataPoint.percentage, discrete);

  return <Rectangle
    data-id="tile"
    className={isClickable ? 'tile_clickable' : 'tile_unclickable'}
    key={`${dataPoint.geotile}/${dataPoint.percentage}`}
    bounds={bounds}
    fillColor={fillColor}
    fillOpacity={0.5}
    stroke
    weight={isSelected ? 2 : 0.5}
    color={isSelected ? '#000000' : 'rgba(0,0,0,0.25)'}
    onclick={tooltip ? undefined : () => {
      if (isSelected) {
        deselect();
        return;
      }
      select();
    }}
  >
    {isClickable && (tooltip || isSelected) && (
      <Tooltip
        sticky={tooltip}
        permanent={!tooltip}
        key={`${dataPoint.geotile}_${tooltip}_${isSelected}`}
      >
        {tooltipContent}
      </Tooltip>
    )}
  </Rectangle>;
};
