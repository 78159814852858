import { parseElemezHostname } from './url';

export const brands = ['panasonic', 'elemez', 'peaktech', 'computacenter'] as const;

export type Brand = typeof brands[number];

export const DEFAULT_BRAND: Brand = 'elemez';

//** Map of hostname prefixes that determine brand if systemConfig.branding is not set */
const brandHostPrefixes: { [key: string]: Brand } = {
  'fedex': 'panasonic',
  'panasonic': 'panasonic',
  'peaktech': 'peaktech',
  'computacenter': 'computacenter'
};

export const getCurrentBrand = (): Brand => {
  const cypressWindow: any = (window as any);
  if (cypressWindow.Cypress && cypressWindow.brand) {
    return cypressWindow.brand;
  }

  const [brand] = parseElemezHostname();
  return brandHostPrefixes[brand] || DEFAULT_BRAND;
};
