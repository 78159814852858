import React, { useState } from 'react';
import { SuggestionsFetchRequestedParams } from 'react-autosuggest';
import classNames from 'classnames';
import { matchSorter } from 'match-sorter';

import { AutosuggestDropdownButton } from './autosuggestDropdownButton';
import { Button } from './button';
import { DEFAULT_VALUE } from '../data-table/dataFormatters';
import { Option } from './optionPickerLib';

import './optionPicker.css';

export interface Props<T = any> {
  options: Option<T>[],
  onSelect: (selected: Option<T>) => void,
  current: Option<T> | undefined,
  selectText?: string,
  renderButton?: (text: string, disabled: boolean, open: boolean) => React.ReactNode,
  getSuggestionClasses?: (suggestion: Option<T>) => string | string[],
  disabled?: boolean,
  disableInput?: true,
  id?: string,
  className?: string
}

export const OptionPicker = <T,>(props: Props<T>) => {
  const {
    options,
    current,
    disabled,
    disableInput,
    className,
    onSelect,
    getSuggestionClasses,
    renderButton,
    id = 'optionPicker',
    selectText = DEFAULT_VALUE,
  } = props;
  const [suggestions, setSuggestions] = useState<Option<T>[]>(options);
  const [dropDownOpen, setDropdownOpen] = useState(false);

  const getSuggestionValue = (suggestion: Option<T>): string => {
    return suggestion?.name || '';
  };

  const renderSuggestion = (suggestion: Option<T>) => {
    const className = classNames('autosuggest__suggestion', getSuggestionClasses && getSuggestionClasses(suggestion));
    return <span className={className}>{suggestion?.name}</span>;
  };

  const onSuggestionsFetchRequested = ({ value }: SuggestionsFetchRequestedParams): void => {
    if (disableInput) { return; }
    const query = value.trim().toLowerCase();
    if (!query) {
      return setSuggestions(options);
    }
    const sorted = matchSorter(options, query, { threshold: matchSorter.rankings.CONTAINS, keys: ['name'] });
    setSuggestions(sorted);
  };

  const onDropdownToggle = (newState: boolean | ((prevState: boolean) => boolean)) => {
    setDropdownOpen(newState);
  };

  const buttonText = current?.name || selectText;
  const defaultButton = (
    <Button
      className={classNames('option-picker__button', { 'option-picker__button--open': dropDownOpen, [props.className]: Boolean(props.className) })}
      type="button"
      text={<div className="option-picker__button-text">{buttonText}</div>}
      disabled={disabled}
    />
  );
  const button = renderButton ? renderButton(buttonText, disabled, dropDownOpen) : defaultButton;

  const classes = classNames('option-picker', className);

  return (
    <div className={classes} data-id={id}>
      <AutosuggestDropdownButton<Option<T>>
        id={id}
        button={button}
        disabled={disabled}
        onSelect={onSelect}
        suggestions={suggestions}
        renderSuggestion={renderSuggestion}
        getSuggestionValue={getSuggestionValue}
        onSuggestionsFetchRequested={onSuggestionsFetchRequested}
        setOpen={onDropdownToggle}
        open={dropDownOpen}
        disableInput={disableInput}
      />
    </div>
  );
};
