import { isNumber } from 'lodash';

export const rebootEventNames = ['deviceRebooted'] as const;
export type RebootEventName = typeof rebootEventNames[number];

export const dropEventNames = ['deviceDropped'] as const;
export type DropEventName = typeof dropEventNames[number];

export const powerEventNames = ['deviceLowPower', 'deviceOnPower', 'deviceOffPower'] as const;
export type PowerEventName = typeof powerEventNames[number];

export const batteryEventNames = ['batteryChanged'] as const;
export type BatteryEventName = typeof batteryEventNames[number];

export const applicationEventNames = ['applicationInstalled', 'applicationUpdated', 'applicationUninstalled'] as const;
export type ApplicationEventName = typeof applicationEventNames[number];

export const timeEventNames = ['timeError', 'timeRecovery'] as const;
export type TimeEventName = typeof timeEventNames[number];

export const networkEventNames = ['networkChanged', 'networkAvailable', 'networkLost', 'bearerChanged'] as const;
export type NetworkEventName = typeof networkEventNames[number];

export const simEventNames = ['simChanged'] as const;
export type SimEventName = typeof simEventNames[number];

export const deviceEventNames = [...rebootEventNames, ...dropEventNames, ...powerEventNames, ...applicationEventNames, ...timeEventNames, ...batteryEventNames, ...networkEventNames, ...simEventNames] as const;
export type DeviceEventName = typeof deviceEventNames[number];

export type DeviceEventCountName = Extract<DeviceEventName, 'deviceRebooted' | 'deviceLowPower' | 'timeError' | 'batteryChanged' | 'deviceDropped' | 'networkChanged' | 'networkAvailable' | 'networkLost' | 'bearerChanged' | 'simChanged'>;

export type BatteryInfo = {
  serialNumber: string
};

export type SIMInfo = {
  serialNumber: string,
  operatorCode: string,
  operatorName: string
};

export type PowerInfo = {
  level: number
};

export type ApplicationInfo = {
  name: string,
  version: string
};

export type NetworkInfo = {
  networkType: string,
  bearerType?: string
};

export const rebootTypes = ['user', 'system', 'unknown'] as const;
export type RebootType = typeof rebootTypes[number];

export type RebootInfo = {
  type: RebootType | string // union with string to allow for unexpected values
};

interface IBaseEvent {
  local: number
}

export interface IRebootEvent extends IBaseEvent {
  name: RebootEventName,
  rebootInfo?: RebootInfo
}

export interface IDropEvent extends IBaseEvent {
  name: DropEventName
}

export interface IPowerEvent extends IBaseEvent {
  name: PowerEventName,
  powerInfo?: PowerInfo
}

export interface ITimeEvent extends IBaseEvent {
  name: TimeEventName
}

export interface IApplicationEvent extends IBaseEvent {
  name: ApplicationEventName,
  applicationInfo?: ApplicationInfo
}

export interface IBatteryEvent extends IBaseEvent {
  name: BatteryEventName,
  oldBatteryInfo?: BatteryInfo,
  newBatteryInfo?: BatteryInfo
}

export interface INetworkEvent extends IBaseEvent {
  name: NetworkEventName,
  oldNetworkInfo?: NetworkInfo,
  newNetworkInfo?: NetworkInfo
}

export interface ISimEvent extends IBaseEvent {
  name: SimEventName,
  oldSimInfo?: SIMInfo,
  newSimInfo?: SIMInfo
}

export type DeviceEvent = IRebootEvent | IDropEvent | IPowerEvent | IApplicationEvent | ITimeEvent | IBatteryEvent | INetworkEvent | ISimEvent;

export const dependencies = {
  isDeviceEvent
};

export function isDeviceEvent(event: any): event is DeviceEvent {
  return isNumber(event.local) && deviceEventNames.includes(event.name);
}

export function areDeviceEvents(events: any[]): events is DeviceEvent[] {
  return events.every(dependencies.isDeviceEvent);
}

export function isRebootEvent(event: DeviceEvent): event is IRebootEvent {
  return dependencies.isDeviceEvent(event) && rebootEventNames.includes(event?.name as any);
}

export function isDropEvent(event: DeviceEvent): event is IDropEvent {
  return dependencies.isDeviceEvent(event) && dropEventNames.includes(event?.name as any);
}

export function isBatteryEvent(event: DeviceEvent): event is IBatteryEvent {
  return dependencies.isDeviceEvent(event) && batteryEventNames.includes(event?.name as any);
}

export function isApplicationEvent(event: DeviceEvent): event is IApplicationEvent {
  return dependencies.isDeviceEvent(event) && applicationEventNames.includes(event?.name as any);
}

export function isPowerEvent(event: DeviceEvent): event is IPowerEvent {
  return dependencies.isDeviceEvent(event) && powerEventNames.includes(event?.name as any);
}

export function isTimeEvent(event: DeviceEvent): event is ITimeEvent {
  return dependencies.isDeviceEvent(event) && timeEventNames.includes(event?.name as any);
}

export function isNetworkEvent(event: DeviceEvent): event is INetworkEvent {
  return dependencies.isDeviceEvent(event) && networkEventNames.includes(event?.name as any);
}

export function isSimEvent(event: DeviceEvent): event is ISimEvent {
  return dependencies.isDeviceEvent(event) && simEventNames.includes(event?.name as any);
}

export function hasApplicationInfo(event: IApplicationEvent): event is Required<IApplicationEvent> {
  return Boolean(event.applicationInfo) && 'name' in event.applicationInfo;
}

export function hasPowerInfo(event: IPowerEvent): event is Required<IPowerEvent> {
  return Boolean(event.powerInfo) && 'level' in event.powerInfo;
}

export function hasRebootInfo(event: IRebootEvent): event is Required<IRebootEvent> {
  return Boolean(event.rebootInfo) && 'type' in event.rebootInfo;
}

export function isValidRebootType(type: RebootType | string): type is RebootType {
  return rebootTypes.includes(type as any);
}
