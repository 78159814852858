import React from 'react';
import { useTranslation } from '@lib/useTypedTranslation';

import { Card } from '../../../components/card/card';

import "./coreMaps.css";
import { TabPanel } from '../../../components/tabs/tab-panel';
import { TabPanelHeader } from '../../../components/tabs/tab-panel-header';
import { useFeatureTogglesContext } from '../../../context/featureToggles';
import { tabsMap } from './tabs';
import { Tab } from '../../../components/tabs/tab';

export const CoreMapsPage: React.FC<{}> = () => {
  const { t } = useTranslation();
  const { t: t2 } = useTranslation('maps');

  const { showMap } = useFeatureTogglesContext();

  const tabs = tabsMap
    .filter(item => showMap(item.type))
    .map(item => {
      const TabContent = item.tab;
      return <Tab title={t2(item.title)} key={item.type}>
        <TabContent />
      </Tab>;
    });

  return (
    <div className="core-maps-container">
      <Card className="core-maps" noPadding>
        <TabPanel>
          <TabPanelHeader>
            <div className="core-maps_page-header" data-id="page-header">
              <div className="title">
                {t('MAPS')}
              </div>
            </div>
          </TabPanelHeader>
          {tabs}
        </TabPanel>
      </Card>
    </div>
  );
};
