import styled from 'styled-components';
import React, { useRef, useState } from 'react';
import { useTranslation } from '@lib/useTypedTranslation';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';

import { NoSelectionOverlay } from '../card/noSelectionOverlay';
import { ExportButton } from '../controls/exportButton';
import { AmChart, coreTheme } from './amChart';
import { addHoverAnimation } from './onHoverAnimation';
import { Theme } from '../../containers/app/themes';
import { getSingleColumnDateChartTooltipAdapter } from './tooltips';
import { drawXAxisLabelsToToday, addLink, addLegend } from './lib';

import './columnDateChart.css';

export function getOnDataUpdated<CountKey>(params: { countKey: CountKey, onDataUpdated?: any }) {
  return (chart: am4charts.XYChart, chartData: any[], prevProps?: any, currentProps?: any) => {
    let maxCount = 0;
    chartData.forEach((data) => (
      maxCount = data[params.countKey] > maxCount ? data[params.countKey] : maxCount
    ));
    (chart.yAxes.values[0] as am4charts.ValueAxis).max = maxCount;
    params.onDataUpdated && params.onDataUpdated(chart);
    return chart;
  };
}

const Container = styled.div<{ bottomBorder: boolean }>`
  position: relative;
  margin-top: 1.5rem;
  ${({ bottomBorder }) => bottomBorder && `border-bottom: 3px solid #edf1f3;`}
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Title = styled.h1`
  margin-bottom: 1.25rem;
  font-weight: ${({ theme }: { theme: Theme}) => theme.font.weights.bold};
  font-size: ${({ theme }: { theme: Theme}) => theme.font.sizes.sixteenPixels};
`;

const ChartContainer = styled.div`
  width: 95%;
  margin-left: auto;
  margin-right: auto;
  height: 14rem;
`;

const NoDataOverlay = styled(NoSelectionOverlay)`
  padding: 0;
`;

const Buttons = styled.div`
  margin-right: 0.625rem;
`;

interface Props<Data extends { date: string }> {
  data: Data[] | null,
  export?: {
    onExportClicked: () => Promise<string>,
    filename: string
  },
  days: number,
  title: string,
  onMount?: (chart: am4charts.XYChart) => am4charts.XYChart,
  onDataUpdated?: (chart: am4charts.XYChart, options: any) => am4charts.XYChart,
  countKey: keyof Data,
  countDescription: string,
  cypressId: string,
  bottomBorder?: boolean,
  colour: string,
  link?: string,
  hasLegend: boolean
}

export function SingleColumnDateChart<Data extends { date: string }>(props: Props<Data>) {
  const { data } = props;
  const [chartReady, setChartReady] = useState(false);
  const chartRef = useRef(null);
  const ns = 'translation';
  const { t } = useTranslation(ns);
  return (
    <Container bottomBorder={props.bottomBorder} data-id={`${props.cypressId}${chartReady ? '--chart-ready' : ''}`}>
      <Header>
        <NoDataOverlay noSelectionText={t('NO_DATA_AVAILABLE', { ns })} show={!data || data.length === 0} />
        <Title>{props.title}</Title>
        {props.export && <Buttons><ExportButton exportFunction={props.export.onExportClicked} filename={props.export.filename} /></Buttons>}
      </Header>
      <ChartContainer className={`${props.cypressId}${chartReady ? '--chart-ready' : ''}`}>
        <AmChart
          tag={props.cypressId}
          chartProvider={createChart}
          dataProvider={() => data}
          onUpdate={getOnDataUpdated<keyof Data>({ countKey: props.countKey, onDataUpdated: props.onDataUpdated })}
          onMount={onMount}
          onDataValidated={() => setChartReady(true)}
          link={data}
          cypressId={props.cypressId}
        />
      </ChartContainer>
    </Container>
  );

  function createChart(id: string) {
    am4core.useTheme(coreTheme);

    const chart: am4charts.XYChart = am4core.create(id, am4charts.XYChart);
    chart.paddingTop = 20;
    chart.minHeight = 220;

    function createSeries(name: string, colour: string) {
      const series = chart.series.push(new am4charts.ColumnSeries());
      series.id = props.countKey as string;
      series.name = name;
      series.dataFields.valueY = props.countKey as string;
      series.dataFields.categoryX = 'date';
      series.sequencedInterpolation = true;

      series.stacked = true;

      series.columns.template.fill = am4core.color(colour);
      series.columns.template.stroke = am4core.color(colour);
      series.columns.template.width = am4core.percent(90);
      series.columns.template.tooltipHTML = '';
      if (props.link) {
        addLink(series, props.link);
      }
      series.columns.template.adapter.add("tooltipHTML", getSingleColumnDateChartTooltipAdapter<Data>({
        colour: props.colour,
        countKey: props.countKey,
        countDescription: props.countDescription
      }));

      series.dummyData = {
        colour: am4core.color(colour)
      };

      addHoverAnimation(series, chart);
      return series;
    }
    const dateAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    dateAxis.dataFields.category = 'date';
    dateAxis.cursorTooltipEnabled = false;
    dateAxis.renderer.grid.template.disabled = true;
    dateAxis.renderer.minGridDistance = 50;

    const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.min = 0;
    valueAxis.renderer.minGridDistance = 30;
    valueAxis.calculateTotals = true;
    valueAxis.contentAlign = 'right';
    valueAxis.maxPrecision = 0;

    createSeries(props.countDescription, props.colour);

    props.hasLegend && addLegend(chart);
    chart.zoomOutButton.disabled = true;

    chartRef.current = chart;
    return chart;
  }

  function onMount(chart: am4charts.XYChart) {
    drawXAxisLabelsToToday(chart, chart.xAxes.values[0] as am4charts.CategoryAxis, props.days, t);
    props.onMount && props.onMount(chart);
    return chart;
  }
}
