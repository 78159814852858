import React, { useContext, useMemo } from 'react';
import { Brand } from '../lib/brands';

export interface BrandContextValue {
  name: Brand,
  isElemez: boolean,
  isPanasonic: boolean,
  isPeakTech: boolean,
  isComputacenter: boolean
}

export const BrandContext = React.createContext<BrandContextValue>(undefined);

interface BrandProviderProps {
  children: React.ReactNode,
  brand: Brand
}

export const BrandProvider = ({ children, brand }: BrandProviderProps) => {
  const value = useMemo(() => {
    return {
      name: brand,
      isElemez: brand === 'elemez',
      isPanasonic: brand === 'panasonic',
      isPeakTech: brand === 'peaktech',
      isComputacenter: brand === 'computacenter'
    };
  }, [brand]);

  return (
    <BrandContext.Provider value={value}>
      {children}
    </BrandContext.Provider>
  );
};

export function useBrandContext() {
  const context = useContext(BrandContext);
  if (context === undefined) {
    throw new Error('useBrandContext must be used within a BrandProvider');
  }
  return context;
}
