import moment from "moment";
import { StackedColumnsDateChartSeries } from "./stackedColumnsDateChart";
import { AxisRenderer, CategoryAxis, DateAxis, XYSeries } from "@amcharts/amcharts4/charts";
import { NumberFormatter } from "@amcharts/amcharts4/core";

export function formatTooltipDay(date: string) {
  return moment.utc(parseInt(date)).format('ll');
}

export function getSingleColumnDateChartTooltipAdapter<Data extends { date: string }>(params: {
  colour: string,
  countKey: keyof Data,
  countDescription: string
}) {
  return (_: any, target: any) => {
    if (!target.tooltipDataItem) {
      return;
    }
    const data = target.tooltipDataItem.dataContext as Data;
    const date = formatTooltipDay(data.date);
    const tooltip = `
    <div class="column-date-chart-tooltip-container" data-date="${data.date}" data-seriesName="${params.countKey as string}">
      <span class="column-date-chart__tooltip-title">${date}</span>
      </br>
      <span>
        <div class="chart-key" style="--colour: ${params.colour}"></div>
        &nbsp;${params.countDescription}: ${data[params.countKey]}
      </span>
    </div>`;
    return tooltip;
  };
}

export function getStackedColumnsDateChartTooltipAdapter<Data extends { date: string }>(params: {
  seriesName: string,
  totalDescription: string,
  formatData?: (dataForSeries: any, allData?: Data) => string,
  amchartsNumberFormat?: string,
  onlyShowCurrentSeries?: boolean,
  formatTooltipDate?: (date: string) => string,
  dateKey?: keyof Data
}) {
  return (_: any, target: any) => {
    if (!target.tooltipDataItem) {
      return;
    }
    const data = target.tooltipDataItem.dataContext as Data;
    const unFormattedDate = params.dateKey ? (data as any)[params.dateKey] : data.date;
    const date = params.formatTooltipDate ? params.formatTooltipDate(unFormattedDate) : unFormattedDate;
    const allSeries = target.clonedFrom.dummyData.allSeries as StackedColumnsDateChartSeries<Data>[];
    const visibleSeries = params.onlyShowCurrentSeries ? allSeries.filter(series => series.dataKey === params.seriesName) : allSeries.filter(series => series.visible);
    const seriesInformation = visibleSeries.map((seriesData) => `</br><span><div class="chart-key" style="--colour: ${seriesData.colour}"></div>&nbsp;${seriesData.description}: ${params.formatData ? params.formatData(data[seriesData.dataKey]) : `{${seriesData.dataKey as string}.formatNumber('${params.amchartsNumberFormat}')}`}</span>`).join('');

    let tooltip;
    if (params.totalDescription) {
      tooltip = `<div class="column-date-chart-tooltip-container" data-date="${data.date}" data-seriesName="${params.seriesName}"><span class="column-date-chart__tooltip-title">${date}</span>
      ${seriesInformation}
      ${visibleSeries.length === allSeries.length ? `</br><span>&nbsp;${params.totalDescription}: ${params.formatData ? params.formatData(undefined, data) : `{valueY.total.formatNumber('${params.amchartsNumberFormat}')}`}</span>` : ''}
      </div>`;
    } else {
      tooltip = `<div class="column-date-chart-tooltip-container" data-date="${data.date}" data-seriesName="${params.seriesName}"><span class="column-date-chart__tooltip-title">${date}</span>
      ${seriesInformation}
      </div>`;
    }
    return tooltip;
  };
}


export function getStackedColumnsDateChartAxisTooltipAdapter<Data extends { date: string }>(params: {
  totalDescription: string,
  formatData?: (dataForSeries: any, allData?: Data) => string,
  amchartsNumberFormat?: string,
  formatTooltipDate?: (date: string) => string,
  dateKey?: keyof Data
}) {
  return (_: string, target: CategoryAxis<AxisRenderer> | DateAxis<AxisRenderer>) => {
    let dataItem = target.getSeriesDataItem(
        target.chart.series.getIndex(0) as XYSeries,
        target.toAxisPosition((target.chart as any).cursor.xPosition),
        true
      ),
      data = dataItem.dataContext as Data;

    const unFormattedDate = params.dateKey ? (data as any)[params.dateKey] : data?.date;
    const date = params.formatTooltipDate ? params.formatTooltipDate(unFormattedDate) : unFormattedDate;

    var tooltipText = `[font-weight: 600]${date}[/]\n`;

    const allSeries = target.dummyData.allSeries as StackedColumnsDateChartSeries<Data>[];

    var seriesInformation = "";
    allSeries.forEach(function (series) {
      if (series.visible) {
        const formattedValue = params.formatData ?
          params.formatData(data[series.dataKey])
          :
          new NumberFormatter().format(data[series.dataKey] as string | number, params.amchartsNumberFormat);

        seriesInformation += "[" + series.colour + " font-size: 20px]■[/] " + series.description + ": " + formattedValue + "\n";
      }
    });

    tooltipText += seriesInformation;

    if (params.totalDescription && allSeries.every(series => series.visible)) {
      const total = allSeries.reduce((acc, series) => acc + (data[series.dataKey] as number), 0);
      const formattedTotalValue = params.formatData ?
        params.formatData(undefined, data)
        :
        new NumberFormatter().format(total, params.amchartsNumberFormat);

      tooltipText += `[font-weight: 600]${params.totalDescription}[/]: ` + formattedTotalValue;
    }

    return tooltipText;
  };
}
