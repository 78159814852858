import * as React from "react";
import { NavLink as Link } from 'react-router-dom';
import styled from 'styled-components';
import { Trans } from "@components/i18n/typedTrans";
import { ITranslationKeys } from "@components/i18n/keys";

export interface ISelectorBarProps {
  id?: string,
  tabs: ISelectorBarTab[],
  initialSelection: string
}

export interface ISelectorBarTab {
  title: string,
  id: string,
  link: string
}

const SelectorBarStyle = styled.div`
  text-align: left;
  min-height: 40px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  box-shadow: 0 2px 4px 0 #dadada;
  ${({ theme }) => `background-color: ${theme.navigationBar.tabs.background.default};`}

  @media print {
    display: none;
  }
`;

const SelectorButton = styled.span<{ active: boolean }>`
  border-top: transparent;
  border-left: transparent;
  border-right: transparent;
  padding-left: 2.5rem;
  padding-right: 2.5rem;
  padding-top: 9px;
  padding-bottom: 10px;
  line-height: 2.5rem;
  font-size: 1.071em;
  font-weight: 600;

  ${({ theme }) => `background-color: ${theme.navigationBar.tabs.background.default};
                    color: ${theme.navigationBar.tabs.color.default};`}

  &:hover {
    ${({ theme }) => `background-color: ${theme.navigationBar.tabs.background.hover};
                      color: ${theme.navigationBar.tabs.color.hover};`}
  }

  ${({ active, theme }) => active && `background-color: ${theme.navigationBar.tabs.background.active};
                                      color: ${theme.navigationBar.tabs.color.active};
                                      padding-bottom: 7px;
                                      outline: none;
                                      border-bottom-style: solid;
                                      border-bottom-width: 3px;
                                      border-bottom-color: ${theme.navigationBar.tabs.borderBottom.active};`}
`;

SelectorButton.displayName = 'SelectorButton';

const SelectorBarLink = styled(Link)`
  &:hover {
    text-decoration: none;
  }
`;

SelectorBarStyle.displayName = 'SelectorBarStyle';

export class SelectorBar extends React.Component<ISelectorBarProps> {
  public render() {
    return (
      <SelectorBarStyle className={`selector-bar ${this.props.id || ''}`}>
        {this.selectorBarTabs(this.props.tabs)}
      </SelectorBarStyle>
    );
  }

  private selectorBarTabs(items: ISelectorBarTab[]): JSX.Element[] {
    return items.map(item =>
      <SelectorBarLink key={item.id} to={item.link}>
        <SelectorButton id={item.id} className={`selector-button ${this.props.initialSelection === item.title ? 'active' : ''}`} active={this.props.initialSelection === item.title}>
          <Trans i18nKey={item.title as keyof ITranslationKeys['translation']} ns="translation">{item.title}</Trans>
        </SelectorButton>
      </SelectorBarLink>
    );
  }
}
