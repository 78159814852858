import { LatLng, latLng, latLngBounds } from "leaflet";
import * as GeoTile from "geotile";
import Color from "color";
import { DataPoint } from "./types";

export const MIN_LATITUDE = -85.05112877980659;
export const MAX_LATITUDE = 85.05112877980659;

export const MIN_LONGITUDE = -180;
export const MAX_LONGITUDE = 179.9999999999999;

export const getDiscreteIntensity = (intensity?: number) => {
  if (intensity === undefined) {
    return undefined;
  }

  return Math.floor(intensity / 10) * 10;
};

export const getDiscreteBand = (intensity?: number) => {
  if (intensity === undefined) {
    return undefined;
  }

  const discreteIntensity = getDiscreteIntensity(intensity);

  switch (discreteIntensity) {
  case 0:
    return '0% - 9%';
  case 10:
    return '10% - 19%';
  case 20:
    return '20% - 29%';
  case 30:
    return '30% - 39%';
  case 40:
    return '40% - 49%';
  case 50:
    return '50% - 59%';
  case 60:
    return '60% - 69%';
  case 70:
    return '70% - 79%';
  case 80:
    return '80% - 89%';
  case 90:
  case 100:
    return '90% - 100%';
  }
};

export const getColorFromIntensity = (intensity?: number, discrete?: boolean) => {
  if (intensity === undefined) {
    return 'transparent';
  }

  const ratio = (discrete ? getDiscreteIntensity(intensity) : intensity) / 100;

  const base = Color('#ff0000');

  const diff = Math.abs(0.5 - ratio) * 1.625;

  if (ratio > 0.5) {
    return base.darken(diff).toString();
  }

  return base.lighten(diff).toString();
};

export const getGeotilePrecisionFromMapZoom = (mapZoom: number): number => {
  return Math.floor(mapZoom || 0) + 2;
};

export const getGeotileGridMapData = <TData extends DataPoint>(mapDataResponse: TData[], sw: LatLng, ne: LatLng, precision: number): Partial<TData>[] => {
  const gridGeotiles = GeoTile.tileIdsForBoundingBox({
    north: Math.min(ne.lat, MAX_LATITUDE),
    east: Math.min(ne.lng, MAX_LONGITUDE),
    south: Math.max(sw.lat, MIN_LATITUDE),
    west: Math.max(sw.lng, MIN_LONGITUDE)
  }, precision);

  const result = gridGeotiles.map((tile: any) => {
    // Convert ids used by the GeoTile package to Slippy Geotile names
    const tileParts = tile.split('_');
    const geotile = `${tileParts[0]}/${tileParts[2]}/${tileParts[1]}`;

    const responseDataPoint = mapDataResponse.find(dataPoint => dataPoint.geotile === geotile);

    if (responseDataPoint) {
      return responseDataPoint;
    }

    return {
      geotile,
      percentage: undefined
    } as Partial<TData>;
  });

  return result;
};

export const getGeotileMapBounds = (sw: LatLng, ne: LatLng, precision: number) => {
  const swTile = GeoTile.tileFromTileId(GeoTile.tileIdFromLatLong(Math.max(sw.lat, MIN_LATITUDE), Math.max(sw.lng, MIN_LONGITUDE), precision));
  const neTile = GeoTile.tileFromTileId(GeoTile.tileIdFromLatLong(Math.min(ne.lat, MAX_LATITUDE), Math.min(ne.lng, MAX_LONGITUDE), precision));

  return latLngBounds(latLng(swTile.latitudeSouth, swTile.longitudeWest), latLng(neTile.latitudeNorth, neTile.longitudeEast));
};

export const getGeotileBounds = (geotile: string) => {
  // Convert Slippy Geotile names to ids used by the GeoTile package
  const tileParts = geotile.split('/');
  const tile = GeoTile.tileFromTileId(`${tileParts[0]}_${tileParts[2]}_${tileParts[1]}`);

  return latLngBounds(latLng(tile.latitudeNorth, tile.longitudeWest), latLng(tile.latitudeSouth, tile.longitudeEast));
};

export const getGeotileLocation = (geotile: string) => {
  const tileParts = geotile.split('/');
  const tile = GeoTile.tileFromTileId(`${tileParts[0]}_${tileParts[2]}_${tileParts[1]}`);

  return `${tile.centerLatitude.toFixed(5)}, ${tile.centerLongitude.toFixed(5)}`;
};
