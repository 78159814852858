import { isNumber } from 'lodash';
import React, { Reducer, useCallback, useContext, useMemo, useReducer, useState } from 'react';
import moment, { Moment } from 'moment';
import styled from 'styled-components';

import { DatePicker } from '../../../../../components/datePicker/datePicker';
import { Button } from '../../../../../components/controls/button';
import { ChipButton } from '../../../../../components/controls/chipButton';
import { useWorldRequest } from '../../../../../lib/useWorldRequest';
import { DeviceSignalStrengthData, exportDeviceSignalStrength, getDeviceSignalStrength, SignalStrengthRange } from '../../../../../services/core/deviceNetwork';
import { DeviceInfoContext } from '../..';
import { useTranslation } from '@lib/useTypedTranslation';
import { Loading } from '../../../../../components/loading/loading';
import { SignalLevelFilter } from '../../../../../components/filters/signalLevelFilter';
import { TTypedTFunction } from '@lib/useTypedTranslation';
import { DetailsMap } from '../../../../../components/detailsMap/detailsMap';
import { NoSelectionOverlay } from '../../../../../components/card/noSelectionOverlay';
import { useTimeout } from '../../../../../lib/useTimeout';
import { ITranslationKeys } from 'components/i18n/keys';
import { ExportButton } from '@components/controls/exportButton';
import { useWorldAction } from '@lib/useWorldAction';
import { useToggleList } from '@lib/useToggleList';

import { SelectedDateChart } from './selectedDateChart';
import { DeviceEvent } from 'services/core/eventsTypes';

export type SignalStrengthChartData = Partial<Omit<DeviceSignalStrengthData, 'date'>> & {
  date: string,
  local: number,
  greyedOut?: number,
  noService?: number,
  noDataAvailable?: boolean,
  events?: DeviceEvent[],
  tooltipDate?: number
};

const dataKeys = ['0', '1', '2', '3', '4', '5'];

const isNoService = (d: DeviceSignalStrengthData) => {
  const isZeroBars = d[0] === 0;
  return !d.operatorName && !d.bearerType && isZeroBars;
};

export const renderDatePicker = (props: any, openCalendar: Function, closeCalendar: Function) => {
  return (
    <DatePickerRenderContainer>
      <DatePickerInput {...props} />
      <div onClick={
        /* istanbul ignore next */
        () => openCalendar()
      }>
        <DatePickerButton
          type="button"
          iconStyle="far fa-calendar-times"
          dataId='open-calendar'
        />
      </div>
    </DatePickerRenderContainer>
  );
};

export type SignalStrengthConfiguration = SignalStrengthRange & {
  colour: string, translationKey: string
};

export const mapDataToChartGetter = (bearerTypes: string[], level: number) => (d: DeviceSignalStrengthData): SignalStrengthChartData => {
  const barWithData = Object.entries(d).find(([key, value]) => isNumber(value) && dataKeys.includes(key));

  if (!barWithData) {
    return {
      date: moment.utc(d.date).format('HH:mm'),
      local: d.date,
      noDataAvailable: true
    };
  }

  const bearerTypeUnknown = !bearerTypes?.includes(d.bearerType);
  const noService = isNoService(d);

  // filter out data that is not in the selected bearer type range, except when there is no service (which is rendered as a separate bar)
  if (bearerTypeUnknown && barWithData && !noService) {
    return {
      ...d,
      date: moment.utc(d.date).format('HH:mm'),
      local: d.local,
      [barWithData[0] as keyof DeviceSignalStrengthData]: null
    };
  }

  const isGreyedOut = (d?.[barWithData?.[0] as keyof DeviceSignalStrengthData] as number) > level;

  let dataFilteredByLevel: DeviceSignalStrengthData & { greyedOut?: number, noService?: number };

  if (isGreyedOut) {
    dataFilteredByLevel = {
      ...d,
      [barWithData?.[0] as keyof DeviceSignalStrengthData]: null,
      greyedOut: d?.[barWithData?.[0] as keyof DeviceSignalStrengthData] as number
    };
  } else if (noService && barWithData) {
    dataFilteredByLevel = {
      ...d,
      [barWithData?.[0] as keyof DeviceSignalStrengthData]: null,
      noService: 5.5
    };
  } else {
    dataFilteredByLevel = {
      ...d,
      [barWithData?.[0] as keyof DeviceSignalStrengthData]: (d?.[barWithData?.[0] as keyof DeviceSignalStrengthData] as number) + 0.5
    };
  }

  return {
    ...dataFilteredByLevel,
    date: moment.utc(dataFilteredByLevel.date).format('HH:mm'),
    local: dataFilteredByLevel.local,
  };
};

export function getLevelToDescriptionMapping(numberOfBars: number, t: TTypedTFunction): { [key: string]: string } {
  return numberOfBars === 4 ?
    { '0': t('VERY_POOR'), '1': t('POOR'), '2': t('MODERATE'), '3': t('GOOD'), '4': t('GREAT') } :
    { '0': t('VERY_POOR'), '1': t('POOR'), '2': t('MODERATE'), '3': t('FAIR'), '4': t('GOOD'), '5': t('GREAT') };
}

function getTooltipTranslations(data: DeviceSignalStrengthData, t: TTypedTFunction, numberOfBars: number, signalLevel: string) {
  const levelToDescriptionMapping = getLevelToDescriptionMapping(numberOfBars, t);

  const noService = !data.operatorName || !data.bearerType;

  const signalPart = noService ? t('NO_SERVICE') : `${levelToDescriptionMapping[signalLevel]} (${t('BAR_COUNT', { count: Number(signalLevel) })})`;

  const signal = `${t('SIGNAL')}: ${signalPart}`;
  const latLong = `${t('LAT/LONG')}: ${isNumber(data?.latitude) && isNumber(data?.longitude) ? `${data?.latitude}, ${data?.longitude}` : '-'}`;
  const operator = `${t('OPERATOR')}: ${data?.operatorName ? data?.operatorName : '-'}`;
  const network = `${t('NETWORK')}: ${data?.bearerType ? t(data?.bearerType.toUpperCase()) : '-'}`;
  const operatorCode = data?.operatorCode ? ` (${data.operatorCode})` : '';

  return { signal, latLong, operator, network, operatorCode };
}

export function getTooltipAdapter(params: {
  numberOfBars: number,
  t: TTypedTFunction
}) {
  return (_: any, target: any) => {
    const { t, numberOfBars } = params;
    const data = target.tooltipDataItem?.dataContext as SignalStrengthChartData;

    const date = moment.utc(data.local).format('HH:mm:ss');

    const seriesName = dataKeys.find(key => data[key as keyof DeviceSignalStrengthData] ?? false);

    if (data.noDataAvailable) {
      return `[font-weight: 600]${date}[/]
      ${t('NO_DATA_AVAILABLE', { ns: 'translation' })}`;
    }

    //To display signal strength in tooltip insted on NaN, when greyedOut.
    const signalLevel = data?.greyedOut ? data.greyedOut.toString() : seriesName;

    const { signal, latLong, operator, network, operatorCode } = getTooltipTranslations(data as unknown as DeviceSignalStrengthData, t, numberOfBars, signalLevel);

    const noService = !data.operatorName || !data.bearerType;

    const tooltip = `[font-weight: 600]${date}[/]
    ${signal}
    ${latLong}
    ${!noService ? `${operator}${operatorCode}` : ''}
    ${!noService ? `${network}` : ''}
    `;

    return tooltip;
  };
}

export function MapTooltipLabel(props: { data: DeviceSignalStrengthData, numberOfBars: number, signalLevel: string }) {
  const { data, numberOfBars, signalLevel } = props;
  const { t } = useTranslation('deviceNetwork');
  const { signal, latLong, operator, network, operatorCode } = getTooltipTranslations(data, t, numberOfBars, signalLevel);

  const noService = !data.operatorName || !data.bearerType;

  return <div>
    <TooltipDate data-id='tooltip-date'>{moment.utc(data.local).format('HH:mm:ss')}</TooltipDate>
    <div>{signal}</div>
    <div>{latLong}</div>
    {!noService && <div>{operator + operatorCode}</div>}
    {!noService && <div>{network}</div>}
  </div>;
}

export function DeviceNetwork(props: { selected?: boolean }) {
  const { id: deviceId, platformType, manufacturer } = useContext(DeviceInfoContext);
  const { list: bearerTypes, toggleItem } = useToggleList(['2g', '3g', '4g', '5g'], false);

  const numberOfBars = (platformType === 'android' && manufacturer?.toLowerCase() !== 'zebra technologies') ? 4 : 5;

  const barColours = useMemo(() => {
    const fourBarColours = [
      'rgb(238, 238, 238)',
      'rgb(0, 0, 0)',
      'rgb(220,5,12)',
      'rgb(241,147,45)',
      'rgb(202,224,171)',
      'rgb(78,178,101)'
    ];
    const fiveBarColours = [
      'rgb(238, 238, 238)',
      'rgb(0, 0, 0)',
      'rgb(220,5,12)',
      'rgb(241,147,45)',
      'rgb(247,240,86)',
      'rgb(202,224,171)',
      'rgb(78,178,101)'
    ];
    return numberOfBars === 4 ? fourBarColours : fiveBarColours;
  }, [numberOfBars]);
  const [level, setLevel] = useState(barColours.length - 1);
  const [selectedDate, setSelectedDate] = useState<number>();
  const [activeBar, setActiveBar] = useState<{ local: number }>();
  const [activeMapMarker, setActiveMapMarker] = useState<{ local: number, zoomTo: boolean }>();
  const { t } = useTranslation(['deviceNetwork', 'translation']);

  const dates = useMemo(() => ({
    from: moment.utc(selectedDate).startOf('day').valueOf(),
    to: moment.utc(selectedDate).endOf('day').valueOf()
  }), [selectedDate]);

  const fetcher = useCallback(() => {
    const params = {
      deviceId,
      to: dates.to,
      numberOfBars: barColours.length - 2
    };
    return getDeviceSignalStrength(params);
  }, [dates.to, deviceId, barColours]);
  const { data, loading } = useWorldRequest(fetcher, { initialLoading: true });
  const { addTimeout } = useTimeout();

  function setDate(date: string | Moment) {
    /* ignored else condition as it is not possible to reproduce
      date as a string, this would mean the date would be
      invalid https://www.npmjs.com/package/react-datetime */
    /* istanbul ignore else */
    if (moment.isMoment(date)) {
      setSelectedDate(date.valueOf());
    }
  }

  const exportDeviceSignalStrengthList = useWorldAction(exportDeviceSignalStrength);
  const onExportClicked = async (): Promise<string> => {
    return exportDeviceSignalStrengthList({ deviceId, to: dates.to });
  };

  const noDataAvailable = !data?.filter(d => d.bearerType || d[0] === 0).length;

  const chartData = data?.map(mapDataToChartGetter(bearerTypes, level));
  /* istanbul ignore next */ /* react leaflet elements not supported in jsdom, testing of map done in cypress */
  const mapData = data?.filter((d) => {
    const barWithData = Object.entries(d).find(([key, value]) => isNumber(value) && dataKeys.includes(key));
    return d.position && (bearerTypes?.includes(d.bearerType) || isNoService(d)) && barWithData;
  }).map((d) => {
    const barWithData = Object.entries(d).find(([key, value]) => isNumber(value) && dataKeys.includes(key));
    const greyOutMarker = (d?.[barWithData?.[0] as keyof DeviceSignalStrengthData] as number) > level;
    const noService = isNoService(d);

    let styleClass: string;

    if (greyOutMarker) {
      styleClass = 'greyed-out';
    } else if (noService && barWithData) {
      styleClass = `no-service`;
    } else {
      styleClass = `total-bars-${numberOfBars} bars-${barWithData[0]}`;
    }

    return {
      position: d.position,
      radius: { size: 20, colour: greyOutMarker ? '#9e9e9d' : barColours[Number(barWithData[0]) + 1] },
      styleClass: styleClass,
      showOnlyCircleOnZoom: true,
      showLabelOnHover: true,
      label: <MapTooltipLabel data={d} numberOfBars={numberOfBars} signalLevel={barWithData[0]} />,
      animateOnClick: true,
      disabled: greyOutMarker,
      onClick: () => {
        setActiveBar({ local: d.local });

        addTimeout(() => {
          setActiveBar(s => d.local === s?.local ? undefined : s);
        }, 2000);
      },
      selected: d.local === activeMapMarker?.local,
      zoomTo: activeMapMarker?.zoomTo
    };
  }) || [];

  return (
    <>
      <SelectorRow>
        <ButtonRow>
          <DatePickerContainer>
            <DatePicker
              dateFormat={`LLLL 'YY`}
              timeFormat={false}
              initialValue={moment()}
              isValidDate={(current: Moment) => current.isBefore(moment()) && current.isAfter(moment().subtract(90, 'day'))}
              onChange={setDate}
              value={selectedDate}
              renderInput={renderDatePicker}
            />
          </DatePickerContainer>
          <StyledSignalLevelFilter
            bars={barColours.slice(2).map((colour, i) => ({ colour, level: i + 1 }))}
            onChange={setLevel}
            disabled={loading || !data?.find(data => data.bearerType)}
          />
          {
            ['2g', '3g', '4g', '5g'].map(bearerType => {
              const isDisabled = loading || noDataAvailable;
              return <FilterButton
                disabled={isDisabled}
                onClick={() => toggleItem(bearerType)}
                active={bearerTypes?.includes(bearerType) && !isDisabled}
                key={bearerType}
                text={t(bearerType.toUpperCase() as keyof ITranslationKeys['deviceNetwork'], { ns: 'deviceNetwork' })}
              />;
            })
          }
        </ButtonRow>
        <ExportButtonContainer>
          <ExportButton exportFunction={onExportClicked} filename={`DeviceSignalStrength_.${moment.utc(selectedDate).format(`LLLL 'YY`)}.csv`} disabled={loading || noDataAvailable}/>
        </ExportButtonContainer>
      </SelectorRow>
      <DataLoading isLoading={loading} transparentOverlay={false}>
        <MapAndChartContainer>
          <NoDataOverlay noSelectionText={t('NO_DATA_AVAILABLE', { ns: 'translation' })} show={noDataAvailable} />
          {/* key added for re-mount of leaflet map when tab goes from being hidden to visible - leaflet map breaks if it has been hidden */}
          <DetailsMap
            id={deviceId}
            key={`map-${props.selected}`}
            visible={props.selected}
            zoomScale={30}
            items={mapData}
          />
          <SelectedDateChart
            selectedDate={selectedDate}
            barColours={barColours} setActiveMapMarker={setActiveMapMarker} activeBar={activeBar} numberOfBars={numberOfBars}
            data={chartData} noSignalDataAvailable={noDataAvailable} />
        </MapAndChartContainer>
      </DataLoading>
    </>
  );
}

const DatePickerContainer = styled.div`
  margin-top: 6px;
  margin-left: 10px;
  max-width: 162px;
  font-weight: 600;

  & .rdtOpen .rdtPicker {
    left: 0;
  }
`;

const ExportButtonContainer = styled.div`
  display: flex;
  margin-top: 10px;
  margin-right: 10px;
`;

const DatePickerRenderContainer = styled.div`
  display: flex;
  max-width: 162px;
`;

const DatePickerInput = styled.input`
  margin-top: 0.571em;
  margin-left: 0.5em;

  &.form-control {
    cursor: pointer;
    flex: 0 0 120px;
    background-color: #fff;
    border: 1px solid #c2d1e0;
    padding: 0.35rem 0.35rem 0.35rem 0.6rem;
    border-radius: 3px 0 0 3px;
    font-size: 1em;
  }
`;

const DatePickerButton = styled(Button)`
  color: #0072af;
  background-color: #fff;
  border-color: #0072af;
  border-radius: 0 3px 3px 0;
  cursor: pointer;
  border: 1px solid #0072af;
  display: flex;
  flex-wrap: nowrap;
  white-space: nowrap;
  flex: 0 0 auto;
  box-shadow: 0 2px 4px 0 rgba(176, 176, 176, 0.5);

  &::after {
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
    content: '\f0d7';  /* caret-down */
    font-size: 12px;
    margin-left: 4px;
    line-height: 1.9rem;
  }

  & > i {
    font-size: 1rem;
    line-height: 1.8rem;
    margin-left: 2px;
  }

  &:hover, &:active {
    color: #fff;
    background-color: #0072af;
    transition: 0.2s;
  }
`;

const MapAndChartContainer = styled.div`
  margin-top: 20px;
  position: relative;
  height: 100%;
  user-select: none;

  .leaflet-container {
    height: 50vh;
    border-radius: 5px;
  }
`;

const NoDataOverlay = styled(NoSelectionOverlay)`
  padding: 0;

  & .noSelectionOverlayInner {
    border-radius: 0;
  }
`;

const ButtonRow = styled.div`
  margin-top: 10px;
  margin-left: 30px;
  height: 40px;
  width: 350px;
  display: flex;
  justify-content: space-around;
  align-items: center;
`;

const SelectorRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  width: 100%;
`;

const FilterButton = styled(ChipButton)`
  margin-top: 6px;
  margin-left: 10px;
  opacity: 1;

  :focus {
    outline: none;
    box-shadow: none;
  }

  &.chip-button--active {
    cursor: pointer;
  }
`;

const DataLoading = styled(Loading)`
  height: 600px;
`;

const StyledSignalLevelFilter = styled(SignalLevelFilter)`
  margin-left: 140px;
  margin-right: 20px;
  position: relative;
`;

const TooltipDate = styled.div`
  font-weight: 600;
`;
