import { request, RequestInitWithRetry } from "../../lib/request";

export type DeviceSignalStrengthData = {
  date: number,
  local: number,
  bearerType: string | null,
  latitude: number | null,
  longitude: number | null,
  position: string | null,
  operatorName: string | null,
  operatorCode: string | null,
  '0': number | null,
  '1': number | null,
  '2'?: number | null,
  '3'?: number | null,
  '4'?: number | null,
  '5'?: number | null
};

export type DeviceSignalStrength = DeviceSignalStrengthData[];

export type SignalStrengthRange = {
  min: number, max: number
};

export type DeviceSignalStrengthParams = {
  deviceId: string,
  to: number,
  numberOfBars: number
};

export type ExportDeviceSignalStrengthParams = {
  deviceId: string,
  to: number
};

export function getDeviceSignalStrength(params: DeviceSignalStrengthParams) {
  return async (options: RequestInitWithRetry): Promise<DeviceSignalStrength> => {
    const input = {
      path: `/api/core/device/signalStrength`,
      qs: { deviceId: params.deviceId },
      body: {
        to: params.to,
        numberOfBars: params.numberOfBars
      }
    };

    const output = await request(input, options);
    return output;
  };
}

export function exportDeviceSignalStrength(requestOptions: RequestInitWithRetry) {
  return async (options: ExportDeviceSignalStrengthParams): Promise<string> => {
    const input = {
      path: `/api/core/device/exportSignalStrength`,
      body: {
        to: options.to,
        deviceId: options.deviceId
      },
      method: 'post'
    };
    return request(input, requestOptions);
  };
}
