import { get } from "lodash";
import { request, RequestInitWithRetry } from "../../lib/request";
import { ISortResult } from "../shared/common";

export type ProcessesListParams = {
  limit: number,
  offset: number,
  search?: string,
  sort?: ISortResult
};

export type ExportProcessesListParams = {
  search?: string,
  sort?: ISortResult
};

export interface IProcess {
  id: string | null,
  processName: string | null,
  productName: string | null,
  activeCount: number | null,
  versionCount: number | null,
  latestProductVersion: string | null,
  latestFileVersion: string | null,
  lastUsed: string | null,
  usedCount: number | null
}

export type ProcessesList = IProcess[];

export type ProcessesListAndCount = {
  list: ProcessesList,
  totalCount: number
};

export interface IGetProcessVersionsParams {
  processName: string,
  productName: string,
  days: number,
  timeZone: string
}

export interface IProcessVersion {
  fileVersion: string,
  productVersion: string
}

export function getProcessesList(params: ProcessesListParams) {
  return async (options: RequestInitWithRetry): Promise<ProcessesListAndCount> => {
    const input = {
      path: `/api/core/processes/list`,
      qs: { limit: params.limit, offset: params.offset },
      body: {
        sort: get(params, 'sort'),
        search: get(params, 'search')
      },
    };
    const output = await request(input, options);
    return output;
  };
}

export function exportProcessesListAction(requestOptions: RequestInitWithRetry) {
  return async (options: ExportProcessesListParams): Promise<string> => {
    const input = {
      path: `/api/core/processes/exportList`,
      body: {
        sort: get(options, 'sort'),
        search: get(options, 'search'),
      },
      method: 'post'
    };
    return request(input, requestOptions);
  };
}

export function getProcessVersions(params: IGetProcessVersionsParams) {
  return async (options: RequestInitWithRetry): Promise<IProcessVersion[]> => {
    const input = {
      path: `/api/core/processes/versions`,
      body: {
        ...params
      },
    };
    const output = await request(input, options);
    return output;
  };
}

export interface IProcessesTrendsParams {
  processName: string,
  productName: string,
  timeZone: string,
  days: number,
  productVersion?: string,
  fileVersion?: string,
  group?: string,
  homeLocationId?: string,
  zoneId?: string
}

export interface IProcessesTrend {
  date: string,
  deviceCount: number
}

export function getProcessesUtilizationTrends(params: IProcessesTrendsParams) {
  return async (options: RequestInitWithRetry): Promise<IProcessesTrend[]> => {
    const input = {
      path: `/api/core/processes/processesUtilizationTrends`,
      body: {
        ...params
      },
    };
    const output = await request(input, options);
    return output;
  };
}

export function exportProcessesUtilizationTrendsAction(options: RequestInitWithRetry) {
  return async (params: IProcessesTrendsParams): Promise<string> => {
    const input = {
      path: `/api/core/processes/exportProcessesUtilizationTrends`,
      body: {
        ...params
      },
    };
    const output = await request(input, options);
    return output;
  };
}

export interface IProcessesDevicesListParameters {
  processName: string,
  productName: string,

  days: number,
  timeZone: string,

  limit: number,
  offset: number,
  sort?: {
    field: string,
    order?: string
  },
  search?: string,

  group?: string | null,
  homeLocationId?: string | null,
  zoneId?: string | null,
  fileVersion?: string,
  productVersion?: string
}

export type ExportProcessesDevicesListParameters = Omit<IProcessesDevicesListParameters, 'limit' | 'offset'>;

export interface IDeviceProcessesListItem {
  lastUsed: string | null,
  fileVersion: string | null,
  productVersion: string | null,
  deviceInfo: {
    id: string,
    serialNumber: string | null,
    imei: string | null,
    assetTag: string | null,
    group: string[] | null,
    homeLocation: {
      id: string,
      name: string
    } | null
  }
}

export interface IDeviceProcessesListResult {
  list: IDeviceProcessesListItem[],
  totalCount: number
}

export type IDeviceProcessesListItemDisplay = IDeviceProcessesListItem & { id: string };

export interface IDeviceProcessesListResultDisplay {
  list: IDeviceProcessesListItemDisplay[],
  totalCount: number
}

export function getProcessDevicesList(params: IProcessesDevicesListParameters) {
  return async (options: RequestInitWithRetry): Promise<IDeviceProcessesListResultDisplay> => {
    const { limit, offset, ...rest } = params;
    const input = {
      path: `/api/core/processes/devicesList`,
      qs: { limit: params.limit, offset: params.offset },
      body: {
        ...rest
      },
    };
    const output = await request(input, options) as IDeviceProcessesListResult;
    return {
      list: output.list.map(item => ({ ...item, id: item.deviceInfo.id })),
      totalCount: output.totalCount
    };
  };
}

export function exportProcessDeviceListAction(options: RequestInitWithRetry) {
  return async (params: ExportProcessesDevicesListParameters): Promise<string> => {
    const input = {
      path: `/api/core/processes/exportDevicesList`,
      body: {
        ...params
      },
    };
    const output = await request(input, options);
    return output;
  };
}

export interface IProcessesUsageTrend {
  date: string,
  cpuActivity: number,
  userActivity: number
}

export function getProcessesUsageTrends(params: IProcessesTrendsParams) {
  return async (options: RequestInitWithRetry): Promise<IProcessesUsageTrend[]> => {
    const input = {
      path: `/api/core/processes/processesUsage`,
      body: {
        ...params
      },
    };
    const output = await request(input, options);
    return output;
  };
}

export function exportProcessesUserActivityAction(options: RequestInitWithRetry) {
  return async (params: IProcessesTrendsParams): Promise<string> => {
    const input = {
      path: `/api/core/processes/exportProcessesUserActivity`,
      body: {
        ...params
      },
    };
    const output = await request(input, options);
    return output;
  };
}

export function exportProcessesCPUActivityAction(options: RequestInitWithRetry) {
  return async (params: IProcessesTrendsParams): Promise<string> => {
    const input = {
      path: `/api/core/processes/exportProcessesCPUActivity`,
      body: {
        ...params
      },
    };
    const output = await request(input, options);
    return output;
  };
}
