import * as React from 'react';
import { useCallback } from 'react';
import { InitialTableState, useTableReducer } from '../../../../components/data-table/lib';
import { List } from '../../../../components/list/list';
import { getProcessDevicesList, IDeviceProcessesListResult, exportProcessDeviceListAction, IDeviceProcessesListItem, IDeviceProcessesListItemDisplay, ExportProcessesDevicesListParameters } from '../../../../services/core/processes';
import { useUserSettingsContext } from '../../../../context/userSettings';
import { useWorldRequest } from '../../../../lib/useWorldRequest';
import { useProcessDeviceListColumns } from './useProcessDeviceListColumns';
import { useWorldAction } from '../../../../lib/useWorldAction';
import useNormalizedHeaderButtons from '../../../../components/controls/normalizedHeaderButtons';

const tableName = 'processDeviceList';
const defaultPageSize = 20;

interface IProps {
  title: string,
  params: Omit<ExportProcessesDevicesListParameters, 'sort'>
}

export const ProcessDeviceList: React.FC<IProps> = ({ params, title }) => {
  const { tablePageSizes } = useUserSettingsContext();
  const columns = useProcessDeviceListColumns();

  const initialTableState: InitialTableState = {
    initialRows: 4,
    limit: tablePageSizes?.[tableName] || defaultPageSize,
    sort: { column: 5, direction: 'desc', field: 'lastUsed' }
  };
  const [tableReducerProperties, tableReducerFunctions] = useTableReducer<IDeviceProcessesListItemDisplay>(tableName, initialTableState);
  const { offset, limit, sort, search } = tableReducerProperties;

  const exportProcessDeviceList = useWorldAction(exportProcessDeviceListAction);
  const onExportClicked = async (): Promise<string> => {
    return exportProcessDeviceList({ sort: { field: sort.field, order: sort.direction }, search, ...params });
  };

  const translationItems = {
    statusText: {
      key: 'DEVICE',
      ns: 'translation'
    },
    tableTitle: {
      text: title
    }
  };

  const additionalHeaderButtons = useNormalizedHeaderButtons({
    export: {
      exportFunction: onExportClicked,
      filename: 'processDeviceList.csv'
    }
  });

  const listFetcher = useCallback(() => {
    return getProcessDevicesList({ limit, offset, sort: { field: sort.field, order: sort.direction }, search, ...params });
  }, [offset, limit, sort, search, params]);

  return (
    <div>
      <div className='core-process-device-list'>
        <List<IDeviceProcessesListItemDisplay, IDeviceProcessesListResult, 'totalCount', 'list', 'id'>
          dataId='core-process-device-list'
          customHeader={false}
          additionalHeaderButtons={additionalHeaderButtons}
          countDataPath='totalCount'
          listDataPath='list'
          hasCheckboxes={false}
          hasFilters={false}
          hasSearch
          translationItems={translationItems}
          columns={columns}
          fetcher={listFetcher}
          tableReducerFunctions={tableReducerFunctions}
          tableReducerProperties={tableReducerProperties}
          useRequestHook={useWorldRequest}
          options={{ rowIdDataPath: 'id' }}
        />
      </div>
    </div>
  );
};
